/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import './LandingNav.scss';
import { Link } from 'react-router-dom';
import CustomButton from '../shared/CustomButton/CustomButton';

type landingNavPropsTypes = {
	getEarlyAccess: () => void;
};

const LandingNav = (props: landingNavPropsTypes) => {
	return (
		<div className="lp-nav">
			<div className="lp-nav-divider" />
			<div className="lp-nav-div">
				<div className="lp-nav-logo-div">
					<img
						src="/assets/images/Logos/banana-logo.png"
						className="lp-nav-logo"
						alt="banana-logo"
					/>
					<h3 className="lp-nav-header">Banana SDK</h3>
				</div>
				<div className="lp-nav-items-div">
					<a
						href="https://banana-wallet-docs.rizelabs.io/"
						rel="noreferrer"
						target={'_blank'}
						className="lp-nav-link"
					>
						Docs
					</a>
					<CustomButton
						size="middle"
						text="Get Early Access"
						onClick={props.getEarlyAccess}
					/>
				</div>
			</div>
		</div>
	);
};

export default LandingNav;
