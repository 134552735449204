/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useEffect, useState } from 'react';
import './LandingPage.scss';
import LandingNav from '../LandingNav/LandingNav';
import LpIntro from './LpIntro/LpIntro';
import UseCases from './UseCases/UseCases';
import Features from './Features/Features';
import RecogBy from './RecogBy/RecogBy';
import Footer from '../Footer/Footer';
import { Modal, Form, Input, message } from 'antd';
import CustomButton from '../shared/CustomButton/CustomButton';
import { addLead } from '../../service';
import { registerAndCreateWallet } from '../../wallet/registerWallet';

const LandingPage = () => {
	const [details, setDetails] = useState({
		name: '',
		email: '',
		company: '',
		social: '',
	});
	const intialState = {
		name: '',
		email: '',
		company: '',
		social: '',
	};
	const [winWidth, setWinWidth] = useState(window.innerWidth);
	const [open, setOpen] = useState(false);
	const showModal = () => {
		setOpen(true);
	};

	const handleSubmit = async () => {
		if (
			details.name === '' ||
			details.company === '' ||
			details.social === ''
		) {
			message.error('Please fill all the fields');
			return;
		}
		const respMessage = await addLead(details);
		setOpen(false);
		setDetails({ ...intialState });
		message.success(respMessage);
	};

	const handleCancel = () => {
		setOpen(false);
	};
	useEffect(() => {
		setWinWidth(window.innerWidth);
	}, [winWidth]);
	const [form] = Form.useForm();
	const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		setDetails({ ...details, [e.target.name]: e.target.value });
	};
	return (
		<div className="lp-div">
			<LandingNav getEarlyAccess={showModal} />
			<LpIntro getEarlyAccess={showModal} />
			<UseCases />
			<Features />
			<RecogBy />
			<Footer getEarlyAccess={showModal} />
			<Modal
				open={open}
				title="Get Early Access"
				onCancel={handleCancel}
				footer={false}
			>
				<Form form={form} layout="vertical">
					<Form.Item label="Name" name="Name" rules={[{ required: true }]}>
						<Input
							placeholder="Gavin Belson"
							name="name"
							onChange={(e) => onChangeHandler(e)}
							value={details.name}
							type="text"
						/>
					</Form.Item>
					<Form.Item
						label="Product/Company Name"
						name="Product/Company Name"
						rules={[{ required: true }]}
					>
						<Input
							placeholder="Hooli inc"
							name="company"
							onChange={(e) => onChangeHandler(e)}
							value={details.company}
							type="text"
						/>
					</Form.Item>
					<Form.Item label="Email" name="Email">
						<Input
							placeholder="gavin@hooli.com"
							name="email"
							onChange={(e) => onChangeHandler(e)}
							value={details.email}
							type="email"
						/>
					</Form.Item>
					<Form.Item
						label="Telegram/ Twitter of company or personnel"
						name="Telegram/ Twitter of company or personnel"
						rules={[{ required: true }]}
					>
						<Input
							placeholder="@gbelson"
							name="social"
							onChange={(e) => onChangeHandler(e)}
							value={details.social}
							type="text"
						/>
					</Form.Item>
					<Form.Item className="lp-modal-submit-btn">
						<CustomButton
							text="Submit"
							size="middle"
							onClick={handleSubmit}
							htmlType="submit"
						/>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};

export default LandingPage;
