/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { LeftOutlined } from '@ant-design/icons';
import './ProcessNav.scss';

const ProcessNav = (props: any) => {
	const { showPrev } = props;
	return (
		<div className="pr-nav">
			<div className="pr-nav-logo-div">
				{showPrev && <LeftOutlined className="pr-nav-prev" />}
				<img
					src="/assets/images/Logos/banana-updated-logo.png"
					className="pr-nav-logo"
					alt="banana-logo"
				/>
				{/* <h3 className="pr-nav-header">Banana</h3> */}
			</div>
		</div>
	);
};

export default ProcessNav;
