/* eslint-disable react/jsx-key */
import React from 'react';
import './Features.scss';

const Features = () => {
	const features = [
		{
			title: 'Zero Knowledge 2-FA',
			desc: 'Enable users to authenticate transactions for higher security while maintaining privacy',
		},
		{
			title: 'Recovery and Nominee',
			desc: 'Recover account with the help of the people you trust and create nominees for your account',
		},
		{
			title: 'Touch ID Authentication',
			desc: 'Execute transaction with touch! Your keys is stored in the secure enclave of the device',
		},
	];

	const renderFeatures = () => {
		return features.map((feat, idx) => {
			return (
				<div className="lp-feat-card">
					<div className="lp-feat-card-header">
						<h3>{feat.title}</h3>
					</div>
					<div className="lp-feat-card-desc">
						<p>{feat.desc}</p>
					</div>
					<img
						src={`/assets/images/LandingPage/feature-${idx + 1}.png`}
						alt={`feature-${idx + 1}`}
						className="lp-feat-img"
					/>
				</div>
			);
		});
	};

	return (
		<div className="lp-feat">
			<h1 className="lp-feat-header">Features</h1>
			<div className="lp-feat-wrapper">
				<div className="lp-feat-card-wrapper">
					<div className="lp-feat-card-1">
						<div className="lp-feat-card-header">
							<h3>{features[0].title}</h3>
						</div>
						<div className="lp-feat-card-desc">
							<p>{features[0].desc}</p>
						</div>
						<img
							src={`/assets/images/LandingPage/feature-1.png`}
							alt={`feature-1`}
							className="lp-feat-img"
						/>
					</div>
					<div className="lp-feat-card-2">
						<div className="lp-feat-card-header">
							<h3>{features[1].title}</h3>
						</div>
						<div className="lp-feat-card-desc">
							<p>{features[1].desc}</p>
						</div>
						<img
							src={`/assets/images/LandingPage/feature-2.png`}
							alt={`feature-2`}
							className="lp-feat-img"
						/>
					</div>
				</div>
				<div className="lp-feat-card-3">
					<div className="lp-feat-card-header">
						<h3>{features[2].title}</h3>
					</div>
					<div className="lp-feat-card-desc">
						<p>{features[2].desc}</p>
					</div>
					<img
						src={`/assets/images/LandingPage/feature-3.png`}
						alt={`feature-3`}
						className="lp-feat-img"
					/>
				</div>
			</div>
		</div>
	);
};

export default Features;
