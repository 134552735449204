/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useState, useImperativeHandle } from 'react';
import CustomWalletInput from '../../shared/CustomWalletInput/CustomWalletInput';
import { BiUser } from 'react-icons/bi';
import { SharedStyles } from '../../shared/SharedStyle';
import { generateRandomString } from '../../../utils/randomMessageGenerator';
import { checkAuth } from '../../../wallet/checkWallet';
import { IWalletMetaData } from '../../../types/controllerTypes';
import {
	getMetaDataForUsername,
	checkWalletExist,
} from '../../../services/controller';
import toast, { Toaster } from 'react-hot-toast';
import { getDeviceEncodedId } from '../../../utils/getDeviceEncodedId';
import { getKeccakHash } from '../../../utils/getKeccakHash';

export type UsernameInfo = {
	username: string;
	isAuthorized: boolean;
};

export type UsernameRef = {
	authorize: () => Promise<UsernameInfo>;
};

export interface IUsernameProps {
	loginTrigger: () => void;
	forwardedRef: React.MutableRefObject<UsernameRef | null>;
}

export type UsernameComponentProps = {
	loginTrigger: () => void;
};

const Username = ({ loginTrigger, forwardedRef }: IUsernameProps) => {
	const [username, setUsername] = useState('');

	const isUsernameAuthorized = async (): Promise<UsernameInfo> => {
		if (!username) {
			toast.error("Username can't be empty");
			return { isAuthorized: false, username: '' } as UsernameInfo;
		}

		if (!/^[a-zA-Z0-9]+$/.test(username)) {
			toast.error('Only letters and numbers are allowed in username');
			return { isAuthorized: false, username: '' } as UsernameInfo;
		}

		const isUsernameExist = await checkWalletExist(username);
		if (isUsernameExist) {
			const walletMeta = await getDeviceEncodedId(username);
			console.log('received this eoa ', walletMeta);
			const randomMessage = generateRandomString(30);

			//! Need to fix this before merging with main
			const isWalletAuthorized = await checkAuth(
				randomMessage,
				getKeccakHash(randomMessage),
				walletMeta.encodedId || '',
				[
					//@ts-ignore
					walletMeta.q0,
					//@ts-ignore
					walletMeta.q1,
				]
			);

			if (isWalletAuthorized) {
				return {
					username,
					isAuthorized: true,
				} as UsernameInfo;
			}
		} else {
			toast.error('Wallet for the provided username do not exist');
			return { isAuthorized: false, username: '' } as UsernameInfo;
		}
		return {
			username,
			isAuthorized: false,
		};
	};

	useImperativeHandle(forwardedRef, () => ({
		authorize: async (): Promise<UsernameInfo> => {
			return isUsernameAuthorized();
		},
	}));

	return (
		<>
			<Toaster />
			<CustomWalletInput
				label="Username"
				placeholder="Type your username here"
				icon={<BiUser />}
				onChange={(e: any) => setUsername(e.target.value)}
				buttonId="login-btn-nd"
			/>
			<SharedStyles.PrimaryButton
				onClick={() => loginTrigger()}
				id="login-btn-nd"
			>
				Log In
			</SharedStyles.PrimaryButton>
		</>
	);
};

export default Username;
