/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable import/no-extraneous-dependencies */
import { Upload } from 'antd';
import { styled } from 'goober';
import { FaRegCopy } from 'react-icons/fa';

export const styles = {
	Container: styled('div')`
		display: flex;
		justify-content: space-between;
		width: 100%;
		background-color: #272727;
		border: 1px solid #6d6d6d;
		border-radius: 8px;
		color: #ffffff;
		padding: 0.5vw;

		@media (max-width: 1024px) {
			padding: 0.5rem;
		}
	`,
	QRContainer: styled('div')`
		cursor: pointer;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		background-color: #272727;
		border: 1px solid #6d6d6d;
		border-radius: 8px;
		color: #ffffff;
		padding: 0.5vw;

		@media (max-width: 1024px) {
			padding: 0.5rem;
		}
	`,
	TextContainer: styled('div')(
		(props: any) => `
        display: flex;
        justify-content: flex-start;
        align-items: ${props.alignItems};
    `
	),
	UploadTextContainer: styled('div')`
		display: flex;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
	`,
	NumberDisplay: styled('div')`
		background-color: #d9d9d9;
		border-radius: 100vw;
		width: 1.5vw !important;
		height: 1.5vw;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #111111;
		font-size: 0.8vw;
		margin-right: 1vw;

		@media (max-width: 1024px) {
			width: 1.3rem !important;
			height: 1.3rem;
			font-size: 0.8rem;
			position: relative;
			left: -1rem;
		}
	`,
	TextWrap: styled('div')`
		display: flex;
		flex-direction: column;
	`,
	Text: styled('p')`
		font-size: 1rem;
		font-family: 'Urbanist-Regular';
		margin-bottom: 0;

		@media (max-width: 1024px) {
			font-size: 0.8rem;
		}
	`,
	BoldText: styled('p')`
		font-size: 1rem;
		font-family: 'Urbanist-Bold';

		@media (max-width: 1024px) {
			font-size: 0.8rem;
		}
	`,
	CopyWrapper: styled('div')`
		display: flex;
		align-items: flex-end;
		padding-bottom: 0.5vw;
	`,
	CopyIcon: styled(FaRegCopy)`
		font-size: 1.5rem;
		color: #ffffff;
		cursor: pointer;

		@media (max-width: 1024px) {
			font-size: 1.3rem;
		}
	`,
	CameraIcon: styled('img')`
		width: 2vw !important;
		color: #ffffff;
		position: relative;
		top: -1.5vh;

		@media (max-width: 1024px) {
			width: 1.5rem !important;
		}
	`,
	CameraIconWrapper: styled('div')`
		width: 100% !important;
		display: flex;
		justify-content: center;
	`,
	UploadComp: styled(Upload)`
		width: 100%;
		min-width: 100%;
		.ant-upload {
			width: 100%;
		}
	`,
	VerticalSpacing: styled('div')(
		(props: any) => `
        height: ${props.height};
    `
	),
};
