/* eslint-disable import/no-extraneous-dependencies */
import { styled } from 'goober';

export const styles = {
	Container: styled('div')`
		background-color: #111111;
		min-height: 100vh;
		color: #ffffff;
		display: flex;
		flex-direction: column;
		align-items: center;
	`,
	Nav: styled('div')`
		background-color: #111111;
		color: #ffffff;
		padding: 2vh 2vw;
		width: 100%;
		display: flex;
		justify-content: start;
		align-items: center;

		@media (max-width: 768px) {
			padding: 4vh 0;
			text-align: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
	`,
	NavTitle: styled('div')`
		font-size: 1.8rem;
		font-family: 'Urbanist-SemiBold';
	`,
	Logo: styled('img')`
		width: 5vw;
		@media (max-width: 768px) {
			width: 12vw;
		}
	`,
	NavRow1: styled('div')`
		display: flex;
		justify-content: center;
		align-items: center;
	`,
	NavSubTitle: styled('div')`
		display: none;
		@media (max-width: 768px) {
			display: block;
			font-size: 1rem;
			font-family: 'Urbanist-Medium';
			color: #ffffff;
		}
	`,
	Content: styled('div')`
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		background-color: #1b1b1b;
		padding: 2vw;
		border-radius: 1vw;
		width: 33vw;
		min-height: 75vh;
		@media (max-width: 1024px) {
			border: none;
			width: 95vw;
			min-height: 50vh;
			background-color: transparent;
		}
	`,
};
