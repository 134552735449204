/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { IWalletMetaData } from '../types/controllerTypes';
import { getCookie, setCookie } from './cookie';
import { getMetaDataForUsername } from '../services/controller';

export type IWalletPresentableData = {
	username?: string;
	walletAddress?: string;
};

const defaultWalletAddress: Pick<IWalletPresentableData, 'walletAddress'> = {
	walletAddress: 'not yet issued',
};

export const fetchWalletDetails = async (): Promise<
	Partial<IWalletPresentableData>[]
> => {
	const usernames: string[] = getCookie('bananaUser');
	const walletPresentableDetails: IWalletPresentableData[] = [];

	if (usernames) {
		for (const username of usernames) {
			const walletMeta: Partial<IWalletMetaData> = getCookie(username);

			const walletPresentableMeta: IWalletPresentableData = {
				...defaultWalletAddress,
				username,
			};

			if (
				!walletMeta.hasOwnProperty('username') ||
				!walletMeta.hasOwnProperty('walletAddress') ||
				walletMeta.walletAddress === ''
			) {
				const updatedWalletMeta: IWalletMetaData = await getMetaDataForUsername(
					username
				);

				if (updatedWalletMeta.hasOwnProperty('walletAddress')) {
					walletPresentableMeta.walletAddress = updatedWalletMeta.walletAddress;
					setCookie(username, JSON.stringify(updatedWalletMeta));
				}
			} else {
				walletPresentableMeta.walletAddress = walletMeta.walletAddress
					? walletMeta.walletAddress
					: 'not yet issued';
			}

			walletPresentableDetails.push(walletPresentableMeta);
		}
	}

	return walletPresentableDetails;
};
