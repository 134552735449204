/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import { v4 as uuidv4 } from 'uuid';
import Axios from 'axios';
import { IWalletPublicValues } from './createWallet';

export const registerAndCreateWallet =
	async (): Promise<IWalletPublicValues> => {
		const uuid = uuidv4();
		const chanllenge = uuidv4();
		const isPlatformSupported =
			await PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
		const authenticationSupport = isPlatformSupported
			? 'platform'
			: 'cross-platform';
		const publicKeyParams = {
			challenge: Uint8Array.from(chanllenge, (c) => c.charCodeAt(0)),
			rp: {
				name: 'Banana Smart Wallet',
			},
			user: {
				id: Uint8Array.from(uuid, (c) => c.charCodeAt(0)),
				name: 'bananawallet',
				displayName: 'Banana Smart Wallet',
			},
			pubKeyCredParams: [{ type: 'public-key', alg: -7 }],
			authenticatorSelection: {
				authenticatorAttachment: authenticationSupport,
				userVerification: 'required',
			},
			timeout: 60000,
			attestation: 'none',
		} as PublicKeyCredentialCreationOptions;

		let publicKeyCredential;
		try {
			publicKeyCredential = await navigator.credentials.create({
				publicKey: publicKeyParams,
			});
		} catch (err) {
			// @ts-ignore
			publicKeyParams.authenticatorSelection.authenticatorAttachment =
				'cross-platform';
			publicKeyCredential = await navigator.credentials.create({
				publicKey: publicKeyParams,
			});
		}

		if (publicKeyCredential === null) {
			// alert('Failed to get credential')
			return Promise.reject(new Error('Failed to create credential'));
		}

		const response = await Axios({
			url: process.env.REACT_APP_LAMBDA_REGISTRATION_URL,
			method: 'post',
			params: {
				aObject: JSON.stringify(
					Array.from(
						new Uint8Array(
							(publicKeyCredential as any).response.attestationObject
						)
					)
				),
				rawId: JSON.stringify(
					//@ts-ignore
					Array.from(new Uint8Array(publicKeyCredential?.rawId))
				),
			},
		});
		return {
			q0: response.data.message.q0hexString,
			q1: response.data.message.q1hexString,
			encodedId: response.data.message.encodedId,
		};
	};

/**
 * when init recovery
 * we need new q values to be settled into user wallet
 * need to update the user's wallet metadata with new q values and encodedId (this should be done via a cron job for setting new q values and new)
 * A cron job should be scheduled to setup a update q values, encodedId and wallet Address
 *
 * if we update walletAddress then need to think about getAccountAddress function as well as it computes address from q values
 * just need to add a check on that method if current wallet is recoverd wallet then use the old address from sender
 */
