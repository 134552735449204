/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useRef, useState } from 'react';
import { styles } from './styles';
import { UploadProps } from 'antd';
import { SharedStyles } from '../../shared/SharedStyle';
import ScannerModal from '../Scanner/Scanner';
import jsQR from 'jsqr';
import { DeviceAdditionData } from '../../../utils/getDeviceAdditionData';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast, { Toaster } from 'react-hot-toast';

type QRProps = {
	setQrImgData: Function;
	DeviceAdditionInfo: DeviceAdditionData;
};

const QR = ({ setQrImgData, DeviceAdditionInfo }: QRProps) => {
	const [qrModal, setQrModal] = useState<boolean>(false);

	const uploadProps: UploadProps = {
		name: 'file',
		multiple: false,
		maxCount: 1,
		accept: 'image/png, image/jpg, image/jpeg',
		beforeUpload: (file: any) => {
			return false;
		},
		onRemove: (file: any) => {
			console.log(file);
		},
		onChange(info) {
			const file = info.file;
			const reader = new FileReader();

			reader.onload = () => {
				const imageData = new Image();

				//@ts-ignore
				imageData.src = reader.result;

				imageData.onload = () => {
					const canvas = document.createElement('canvas');
					const context = canvas.getContext('2d');

					canvas.width = imageData.width;
					canvas.height = imageData.height;

					context?.drawImage(imageData, 0, 0);

					const imageDataArray = context?.getImageData(
						0,
						0,
						canvas.width,
						canvas.height
					).data;

					const code = jsQR(imageDataArray!, canvas.width, canvas.height);
					setQrImgData(code?.data);
				};
			};

			//@ts-ignore
			reader.readAsDataURL(file);
		},
	};

	const startScanning = () => {
		setQrModal(true);
	};

	const setDecodedContent = (content: string) => {
		setQrImgData(content);
	};

	//! make the link to open in new tab

	return (
		<>
			<styles.Container>
				<Toaster />
				<styles.TextContainer>
					<styles.NumberDisplay>1</styles.NumberDisplay>
					<styles.TextWrap>
						<styles.Text>Go to new device to get the QR Code</styles.Text>
						<styles.BoldText>
							Open this link:
							{DeviceAdditionInfo.deviceAdditionUrl.slice(0, 30) + '...'}
						</styles.BoldText>
					</styles.TextWrap>
				</styles.TextContainer>
				<styles.CopyWrapper>
					<CopyToClipboard
						text={DeviceAdditionInfo.deviceAdditionUrl}
						onCopy={() => toast.success('Copied to clipboard')}
					>
						<styles.CopyIcon />
					</CopyToClipboard>
				</styles.CopyWrapper>
			</styles.Container>
			<styles.VerticalSpacing height="3.5vh" />

			<styles.QRContainer onClick={() => startScanning()}>
				<styles.UploadTextContainer>
					<styles.NumberDisplay>2</styles.NumberDisplay>
					<styles.Text>Qr scan</styles.Text>
				</styles.UploadTextContainer>
				<styles.CameraIconWrapper>
					<styles.CameraIcon src="/assets/images/DeviceAdd/Camera.png" />
				</styles.CameraIconWrapper>
			</styles.QRContainer>

			<styles.VerticalSpacing height="5.5vh" />
			<styles.UploadComp {...uploadProps}>
				<SharedStyles.SecondaryButton>Upload</SharedStyles.SecondaryButton>
			</styles.UploadComp>

			{qrModal && (
				<ScannerModal showModal={qrModal} setQRContent={setDecodedContent} />
			)}
		</>
	);
};

export default QR;
