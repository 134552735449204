import React from 'react';
import './RecogBy.scss';

const RecogBy = () => {
	return (
		<div className="lp-rb-comp">
			<svg
				className="lp-rb-svg"
				viewBox="0 0 1442 145"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M1441.18 0.740494L1441.18 144.973L-6.78244e-05 144.973C0.176244 -1.00006 1441 146.714 1441.18 0.740494Z"
					fill="#F5C14B"
				/>
			</svg>
			<div className="lp-rb">
				<h1 className="lp-rb-header">Recognized By</h1>
				<div className="lp-rb-img-div">
					<div className="lp-rb-img-div-1">
						<img
							src="/assets/images/LandingPage/rb-10.png"
							alt="rb-10.png"
							className="lp-rb-img-9"
						/>
						<img
							src="/assets/images/LandingPage/rb-1.png"
							alt="rb-1.png"
							className="lp-rb-img-1"
						/>
						<img
							src="/assets/images/LandingPage/rb-0.png"
							alt="rb-9.png"
							className="lp-rb-img-0"
						/>
						<img
							src="/assets/images/LandingPage/rb-2.png"
							alt="rb-2.png"
							className="lp-rb-img-2"
						/>
						<img
							src="/assets/images/LandingPage/rb-3.png"
							alt="rb-3.png"
							className="lp-rb-img-3"
						/>
					</div>
					<div className="lp-rb-img-div-2">
						<img
							src="/assets/images/LandingPage/rb-4.png"
							alt="rb-4.png"
							className="lp-rb-img-4"
						/>
						<img
							src="/assets/images/LandingPage/rb-5.png"
							alt="rb-5.png"
							className="lp-rb-img-5"
						/>
						<img
							src="/assets/images/LandingPage/rb-6.png"
							alt="rb-6.png"
							className="lp-rb-img-6"
						/>
						<img
							src="/assets/images/LandingPage/rb-7.png"
							alt="rb-7.png"
							className="lp-rb-img-7"
						/>
						<img
							src="/assets/images/LandingPage/rb-8.png"
							alt="rb-8.png"
							className="lp-rb-img-8"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RecogBy;
