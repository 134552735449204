/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { Rings } from 'react-loader-spinner';
import './LotteryLoader.scss';

type loaderProps = {
	text: string;
};

const LotteryLoader = (props: loaderProps) => {
	return (
		<div className="lottery-loader">
			<Rings
				height="158"
				width="158"
				color="#FFD42B"
				radius="6"
				visible={true}
				ariaLabel="rings-loading"
			/>
			<p className="lottery-loader-txt">{props.text}</p>
		</div>
	);
};

export default LotteryLoader;
