import React, { PropsWithChildren, useState } from 'react';
import {
	WalletContextType,
	IWalletMeta,
	IWalletPlatformMeta,
} from '../types/walletContext';

export const WalletContext = React.createContext<WalletContextType | null>(
	null
);

export const WalletMetaProvider: React.FC<PropsWithChildren> = ({
	children,
}) => {
	const [walletStatus, setWalletStatus] = useState<IWalletMeta>();
	const [walletPlatformMeta, setPlatformMeta] = useState<IWalletPlatformMeta>();

	const setStatus = (status: boolean): void => {
		setWalletStatus({ walletCreationStatus: status });
	};

	const setWalletPlatformMeta = (
		walletPlatformMetaData: IWalletPlatformMeta
	): void => {
		setPlatformMeta(walletPlatformMetaData);
	};

	return (
		<WalletContext.Provider
			value={{
				walletStatus,
				setStatus,
				setWalletPlatformMeta,
				walletPlatformMeta,
			}}
		>
			{children}
		</WalletContext.Provider>
	);
};
