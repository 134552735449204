/* eslint-disable import/no-extraneous-dependencies */
import { styled } from 'goober';
import { IoExitOutline } from 'react-icons/io5';
import { BiPlus } from 'react-icons/bi';

export const styles = {
	ParentWrapper: styled('div')`
		background-color: #111111;
		display: flex;
		flex-direction: column;
		align-items: center;
		max-height: 85vh;
		overflow-y: auto;
		width: 100%;
	`,
	Wrapper: styled('div')`
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 35%;
		padding: 3.125rem 1rem;
		background-color: #111111;
		color: #ffffff;
		min-height: 80vh;
		max-height: 80vh;
		overflow-y: auto;
		@media (max-width: 1024px) {
			width: 100%;
		}
	`,
	Header: styled('div')`
		font-size: 2rem;
		font-family: 'Urbanist-Semibold';
		color: #fbd025;
		margin-top: 2.25rem;
		margin-bottom: 0.313rem;
	`,
	Spacing: styled('div')`
		margin-bottom: 1.5rem;
	`,
	WalletDetails: styled('div')`
		display: flex;
		justify-content: space-between;
		padding: 0.5rem 1rem;
		border: 1px solid #fbd025;
		border-radius: 7px;
		cursor: pointer;
		margin: 0.5rem 0;
		min-width: 95%;
	`,
	WalletUsername: styled('div')`
		font-size: 1.125rem;
		font-family: 'Urbanist-Medium';
		color: #ffffff;
		padding-bottom: 0.35rem;

		@media (max-width: 1024px) {
			font-size: 1rem;
		}
	`,
	WalletAddress: styled('div')`
		font-size: 0.88rem;
		font-family: 'Urbanist-Medium';
		color: #b4b4b4;

		@media (max-width: 1024px) {
			font-size: 0.75rem;
		}
	`,
	WalletText: styled('div')`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		margin-right: 3rem;

		@media (max-width: 1024px) {
			margin-right: 1rem;
		}
	`,
	PrimaryButton: styled('button')`
		width: 95%;
		background-color: #ffd42b;
		color: #000000;
		border: none;
		cursor: pointer;
		border-radius: 8px;
		padding: 1rem 1rem;
		font-family: 'Urbanist-Bold';
		@include flexBox(row, center, center);
		&:hover {
			background-color: #000000 !important;
			color: #ffd42b !important;
		}
	`,
	SecondaryButton: styled('button')`
		width: 95%;
		color: #ffffff;
		cursor: pointer;
		background-color: transparent;
		border-radius: 8px;
		border: 1px solid #ffd42b;
		padding: 1rem 1rem;
		margin-top: 3%;
		font-family: 'SpaceGrotesk-Bold';
		@include flexBox(row, center, center);
		&:hover {
			background-color: #000000;
			color: #ffd42b;
		}
	`,
	Form: styled('div')`
		width: 95%;
	`,
	Text: styled('p')`
		font-family: 'Urbanist-Regular';
		font-size: 1rem;
		color: #a3a3a3;
		margin: 0 2vw;
	`,
	ButtonTxt: styled('p')`
		font-family: 'Urbanist-SemiBold';
		font-size: 1rem;
		margin: 0;
	`,
	ButtonPar: styled('div')`
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 95%;
	`,
	ButtonSpacing: styled('div')`
		padding-right: 5%;
	`,
	VerticalSpacing: styled('div')`
		padding-bottom: 5%;
	`,
	ExitIcon: styled(IoExitOutline)`
		font-size: 1.3rem;
	`,
	PlusIcon: styled(BiPlus)`
		font-size: 1.3rem;
	`,
	HorizontalLine: styled('div')`
		width: 100%;
		border-top: 1px solid #444444;
	`,
	OrWrapper: styled('div')`
		width: 95%;
		padding: 10% 0;
		display: flex;
		justify-content: center;
		align-items: center;
		@media (max-width: 768px) {
			display: none;
		}
	`,
	TransparentButton: styled('button')`
		width: 100%;
		color: #ffffff;
		cursor: pointer;
		background-color: transparent;
		border-radius: 8px;
		border: 1px solid #585858;
		padding: 1rem 1rem;
		font-family: 'Urbanist-Bold';
		&:hover {
			background-color: #000000;
			color: #ffd42b;
		}
		@media (max-width: 768px) {
			display: none;
		}
	`,
	ButtonWrapper: styled('div')`
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		@media (max-width: 768px) {
			display: none;
		}
	`,
	FloatButtonWrapper: styled('div')`
	`,
	FloatButtonTip: styled('p')`
		font-family: 'Urbanist-Medium';
		font-size: 0.875rem;
	`,
	FloatButton: styled('button')`
		width: 3.125rem;
		height: 3.125rem;
		border-radius: 50%;
		background-color: #ffd42b;
		border: none;
		cursor: pointer;
		color: #111;
		@include flexBox(row, center, center);
	`,
	FloatOption: styled('button')`
		width: 2.8rem;
		height: 2.8rem;
		border-radius: 50%;
		background-color: #ffd42b;
		border: none;
		cursor: pointer;
		color: #111;
		@include flexBox(row, center, center);
	`
};
