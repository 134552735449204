/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import './Loader.scss';
import React from 'react';
import { Spin } from 'antd';

const Loader = (props: any) => {
	return (
		<Spin className="spinner" spinning={props.isLoading} tip={props.message}>
			{props.children}
		</Spin>
	);
};

export default Loader;
