/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { Link } from 'react-router-dom';
import CustomButton from '../shared/CustomButton/CustomButton';
import './Footer.scss';

type footerPropsTypes = {
	getEarlyAccess: () => void;
};

const Footer = (props: footerPropsTypes) => {
	return (
		<div className="lp-footer">
			<div className="lp-footer-div-1">
				<h1 className="lp-footer-header">
					Provide The Best Wallet Experience on your App
				</h1>
				<CustomButton
					size="large"
					text="Get Early Access"
					onClick={props.getEarlyAccess}
				/>
			</div>
			<div className="lp-footer-div-2">
				<div className="lp-footer-brand">
					<div>
						<img
							src="/assets/images/Logos/banana-logo.png"
							alt="banana-logo"
							className="lp-footer-brand-img"
						/>
					</div>
					<div className="lp-footer-brand-txt-div">
						<h3 className="lp-footer-brand-prod">Banana SDK</h3>
						<h3 className="lp-footer-brand-org">By Rize Labs</h3>
					</div>
				</div>
				<div className="lp-footer-links-div">
					<ul className="lp-footer-links-ul">
						<li className="lp-footer-links-header">Quick Links</li>
						<li className="lp-footer-links-li">
							<a
								href="https://banana-wallet-docs.rizelabs.io/"
								rel="noreferrer"
								target={'_blank'}
								className="lp-footer-links-li"
							>
								Developer Docs
							</a>
						</li>
						<li className="lp-footer-links-li">
							<a
								href="https://twitter.com/wallet_banana"
								rel="noreferrer"
								target="_blank"
								className="lp-footer-links-li"
							>
								Twitter
							</a>
						</li>
						<li className="lp-footer-links-li">
							<a
								href="https://discord.gg/3fJajWBT3N"
								rel="noreferrer"
								target="_blank"
								className="lp-footer-links-li"
							>
								Discord
							</a>
						</li>
					</ul>
				</div>
			</div>
			<div className="lp-footer-div-3">
				<span className="lp-footer-cr-txt">
					Built by Rize Labs, HQ Singapore with{' '}
				</span>
				<img
					src="/assets/images/LandingPage/like.png"
					alt="like"
					className="lp-footer-cr-img"
				/>
			</div>
		</div>
	);
};

export default Footer;
