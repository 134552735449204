/* eslint-disable */
import React, { useRef, useState, useEffect } from 'react';
import { styles } from './styles';
import Username, { UsernameRef } from '../../shared/Username/Username';
// import WalletLoader from '../WalletSDKComponents/WalletLoader/WalletLoader';
import Loader from './Loader/Loader';
import QR from '../../shared/QR/QR';
import toast, { Toaster } from 'react-hot-toast';
import { isPublicKeyValid } from '../../../validations/isValidPublicKeyObject';
import { AddNewDeviceTransaction } from '../../../wallet/transactions';
import { ethers } from 'ethers';
import { MUMBAI_RPC, Banana, Chains } from '@rize-labs/banana-wallet-web-sdk';
import {
	DeviceAdditionData,
	getDeviceAdditionData,
} from '../../../utils/getDeviceAdditionData';
import Success from '../../shared/Success/Success';
import { clearAllCookies } from '../../../utils/clearCookies';

const DeviceAddition = () => {
	const usernameRef: React.MutableRefObject<UsernameRef | null> = useRef(null);
	const [deviceAdditionInfo, setDeviceAdditionInfo] =
		useState<DeviceAdditionData>({} as DeviceAdditionData);
	const [qrData, setQrData] = useState<string>('');
	const [walletname, setWalletname] = useState<string>('');

	useEffect(() => {
		if (qrData !== '') {
			if (isPublicKeyValid(qrData)) {
				toast.success('Please wait Adding device!');
				setCurrentStep((prevStep) => prevStep + 1);
				// eslint-disable-next-line @typescript-eslint/no-floating-promises
				initiateTransactionForDeviceAddition(qrData);
			} else {
				toast.error('QR is not valid! Please upload again');
			}
		}
	}, [qrData]);

	const initiateTransactionForDeviceAddition = async (
		publicKeyData: string
	) => {
		const publicKey = JSON.parse(publicKeyData);
		const provider = new ethers.providers.JsonRpcProvider(MUMBAI_RPC);
		const bananaInstance = new Banana(Chains.mumbai);
		try {
			const wallet = await bananaInstance.connectWallet(walletname);
			await AddNewDeviceTransaction(publicKey, provider, wallet, walletname);
			toast.success('new device added successfully');
			setCurrentStep((prevStep) => prevStep + 1);
		} catch (err) {
			toast.error('Some error occured while adding device!');
			setCurrentStep(1);
			setQrData('');
			console.log(err);
		}
	};

	/**
	 * 1: username prompt
	 * 2: username authentication
	 * 3: QR verification
	 * 4: Adding device
	 * 5: Success page
	 */

	const [currentStep, setCurrentStep] = useState<number>(1);

	const usernameLogin = async () => {
		try {
			setCurrentStep((prevStep) => prevStep + 1);
			const authorizationData = await usernameRef.current?.authorize();
			if (authorizationData!.isAuthorized) {
				setCurrentStep((prevStep) => prevStep + 1);
				setWalletname(authorizationData!.username);
				const deviceAdditionData: DeviceAdditionData = getDeviceAdditionData();
				setDeviceAdditionInfo(deviceAdditionData);
				// clearAllCookies();
			} else {
				setCurrentStep((prevStep) => prevStep - 1);
			}
		} catch (err) {
			setCurrentStep((prevStep) => prevStep - 1);
			toast.error('You are not authorized to use this wallet');
		}
	};

	return (
		<styles.Container>
			<Toaster />
			<styles.Nav>
				<styles.NavRow1>
					<styles.Logo src="/assets/images/Logos/banana-logo.png" />
					<styles.NavTitle>Banana</styles.NavTitle>
				</styles.NavRow1>
				<styles.NavSubTitle>Work without limits</styles.NavSubTitle>
			</styles.Nav>
			<styles.Content>
				{currentStep === 1 && (
					<Username loginTrigger={usernameLogin} forwardedRef={usernameRef} />
				)}
				{currentStep === 2 && <Loader text="Authenticating..." />}
				{currentStep === 3 && (
					<QR
						setQrImgData={setQrData}
						DeviceAdditionInfo={deviceAdditionInfo}
					/>
				)}
				{currentStep === 4 && <Loader text={'Adding new device...'} />}
				{currentStep === 5 && (
					<Success title={'New device added successfully'} />
				)}
			</styles.Content>
		</styles.Container>
	);
};

export default DeviceAddition;
