/* eslint-disable @typescript-eslint/naming-convention */
import React, { PropsWithChildren } from 'react';
import { Rings } from 'react-loader-spinner';
import { styles } from './styles';

type loaderProps = {
	text: string;
	isLoading: boolean;
};

const WalletLoader: React.FC<PropsWithChildren<loaderProps>> = ({
	children,
	text,
	isLoading,
}) => {
	return (
		<styles.ParentWrapper>
			<styles.Wrapper>
				{isLoading && (
					<Rings
						height="158"
						width="158"
						color="#FFD42B"
						radius="6"
						visible={true}
						ariaLabel="rings-loading"
					/>
				)}
				{!isLoading && children}
				{isLoading && <styles.Sub>{text}</styles.Sub>}
			</styles.Wrapper>
		</styles.ParentWrapper>
	);
};

export default WalletLoader;
