/* eslint-disable import/no-extraneous-dependencies */
import { styled } from 'goober';
import { BiLink } from 'react-icons/bi';

export const styles = {
	Text: styled('p')`
		font-family: 'Urbanist-SemiBold';
		font-size: 1.25rem;
		margin: 0;
		text-align: left;
		width: 100%;
	`,
	BiLink: styled(BiLink)`
		margin-right: 0.5vw;
		font-size: 1.5rem;
	`,
	TextWrapper: styled('div')`
		display: flex;
		justify-content: center;
		align-items: center;
	`,
	Wrapper: styled('div')`
		width: 100%;
		@media (max-width: 1024px) {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
	`,
};
