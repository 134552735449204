/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { IconWrapper, Wrapper, Text, Icon } from './styles';

type modalNavProps = {
	title: string;
	showIcon: boolean;
};

const ModalNav = (props: modalNavProps) => {
	return (
		<Wrapper>
			<IconWrapper>
				{props.showIcon && <Icon src="/assets/images/arrow-left.png" />}
			</IconWrapper>
			<Text>{props.title}</Text>
		</Wrapper>
	);
};

export default ModalNav;
