/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { Rings } from 'react-loader-spinner';
import { styles } from './styles';

type loaderProps = {
	text: string;
};

const WalletLoader = (props: loaderProps) => {
	return (
		<styles.Wrapper>
			<Rings
				height="158"
				width="158"
				color="#FFD42B"
				radius="6"
				visible={true}
				ariaLabel="rings-loading"
			/>
			<styles.Sub>{props.text}</styles.Sub>
		</styles.Wrapper>
	);
};

export default WalletLoader;
