/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { styles } from './styles';
import { SharedStyles } from '../../../shared/SharedStyle';
import { downloadImageFromBase64Url } from '../../../../utils/downloader';

type shareQRProps = {
	qrImg: string;
	onShare: () => void;
};
const ShareQR = (props: shareQRProps) => {
	const { qrImg, onShare } = props;
	return (
		<styles.Wrapper>
			<SharedStyles.VerticalSpacing
				height={window.innerWidth < 1024 ? '4rem' : '8rem'}
			/>
			<SharedStyles.SuccessContainer>
				<styles.Icon src="/assets/images/WalletSDK/info.png" />
				<styles.ContainerTxt>
					This QR code needs to be scanned by the old device that you want to
					add to this wallet.
					<br />
					<styles.BoldTxt>Note: Go to old device for scan</styles.BoldTxt>
				</styles.ContainerTxt>
			</SharedStyles.SuccessContainer>
			<SharedStyles.VerticalSpacing height={'2rem'} />
			<styles.QrImg src={qrImg} alt="qr-code-img" />
			<SharedStyles.VerticalSpacing height={'2rem'} />
			<SharedStyles.SecondaryButton>
				<styles.BtnWrapper>
					<styles.ShareIcon />
					<styles.BtnTxt
						onClick={() => downloadImageFromBase64Url(qrImg, 'publicKeysQr')}
					>
						Download
					</styles.BtnTxt>
				</styles.BtnWrapper>
			</SharedStyles.SecondaryButton>
		</styles.Wrapper>
	);
};

export default ShareQR;
