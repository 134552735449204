/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable import/no-extraneous-dependencies */
import { styled } from 'goober';

export const SharedStyles = {
	Divider: styled('div')`
		width: 100%;
		border-top: 1px solid #444444;
	`,
	PrimaryButton: styled('button')`
		width: 100%;
		background-color: #ffd42b;
		color: #000000;
		border: none;
		cursor: pointer;
		border-radius: 8px;
		padding: 1rem 1rem;
		font-family: 'Urbanist-Bold';
		@include flexBox(row, center, center);
		&:hover {
			background-color: #000000 !important;
			color: #ffd42b !important;
		}
	`,
	SecondaryButton: styled('button')`
		width: 100%;
		color: #ffffff;
		cursor: pointer;
		background-color: transparent;
		border-radius: 8px;
		border: 1px solid #ffd42b;
		padding: 1rem 1rem;
		font-family: 'SpaceGrotesk-Bold';
		&:hover {
			background-color: #000000;
			color: #ffd42b;
		}
	`,
	TransparentButton: styled('button')`
		width: 100%;
		color: #ffffff;
		cursor: pointer;
		background-color: transparent;
		border-radius: 8px;
		border: 1px solid #585858;
		padding: 1rem 1rem;
		font-family: 'Urbanist-Bold';
		&:hover {
			background-color: #000000;
			color: #ffd42b;
		}
	`,
	ButtonWrapper: styled('div')`
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
	`,
	VerticalSpacing: styled('div')(
		(props: any) => `
        height: ${props.height};
    `
	),
	SuccessContainer: styled('div')`
		display: flex;
		justify-content: center;
		align-items: center;
		background: rgba(7, 189, 116, 0.3);
		border-radius: 8px;
		padding: 0.75rem;
		width: 100%;
	`,
};
