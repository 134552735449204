/* eslint-disable */
import React, { useState, useEffect } from 'react';
import './BananaLottery.scss';
import ProcessNav from '../shared/ProcessNav/ProcessNav';
import PrimaryButton from '../shared/PrimaryButton/PrimaryButton';
import { SharedStyles } from '../shared/SharedStyle';
import Loader from '../shared/Loader/Loader';
import { Banana } from '@rize-labs/banana-wallet-sdk-demo/dist/BananaProvider';
import { Chains } from '@rize-labs/banana-wallet-sdk-demo/dist/Constants';
// import { Banana, Chains } from '@rize-labs/banana-wallet-sdk';
import nftAbi from '../../abi/lotteryNFT.json';
import { ethers } from 'ethers';
import SecondaryButton from '../shared/SecondaryButton/SecondaryButton';
import Confetti from 'react-confetti';
import LotteryLoader from './LotteryLoader/LotteryLoader';
import { TwitterShareButton, TwitterIcon } from 'react-share';
import { title } from 'process';

const BananaLottery = () => {
	const [spin, setSpin] = useState(false);
	const [ring1, setRing1]: any = useState();
	const [ring2, setRing2]: any = useState();
	const [ring3, setRing3]: any = useState();
	const [price, setPrice]: any = useState(4);
	const [realBet, setRealBet]: any = useState();
	const [jackpot, setJackpot]: any = useState(0);
	const [balance, setBalance]: any = useState(100000);
	const [walletConnected, setWalletConnected]: any = useState(false);
	const [result, setResult]: any = useState(false);
	const [rewardClaimed, setRewardClaimed] = useState(false);
	//   const [bananaSdkInstance, setBananSdkInstance] = useState()
	const [loading, setLoading]: any = useState(false);
	const [rewardLoader, setRewardLoader] = useState(false);
	// '0x7aFf7eE03bA46CdeC0C3011DD4dbf1740AE53bfc'
	const [walletAddress, setWalletAddress]: any = useState(null);
	const [eoaAddress, setEoaAddress]: any = useState(null);
	const [walletInstance, setWalletInstance] = useState(null);
	const [tweetTitle, setTweetTitle] = useState('');
	const [currentStep, setCurrentStep] = useState<number>(1);
	const nfts = ['t-shirt', 't-shirt', 't-shirt', 't-shirt'];

	const ZUCK_NFT = '0xdDbA21aBd3918209a3cd3F4ff2339Bbd4593531E';
	const MUSK_NFT = '0x55707eb4BA953523772CB6eF86d795eFCd6F2D61';
	const ALIEN_NFT = '0x63512D102BACc2e8189D890A74861886840c6a6f';
	const LIZARD_NFT = '0x26570371e1BF1318E6CbC1657a3dA3CCf3dC9B5D';
	const BANANA_NFT = '0x357c1E215CCb918d54a37FF63Dc320417CF0fD66';

	const PRIVATE_KEY_EXPOSED =
		'83d84df6890312b71bfe3e5860e714bca6f6e1e1d136bafd57b2ee1b057d5676';
	const PUBLIC_KEY_EXPOSED = '0x8eDddFA5DB1A5901E17E823Af29501741ab2b024';

	/**
	 * Add heading
	 * don't show wallet being created all the time if the connect wallet method is getting called then show wallet being connected
	 * at the last screen of where we show user which NFT he won please align the NFT name in centre with some confetti emoji around it
	 * Also if possible can we have some confetti falling it the user gets a three in a row
	 * please slow down the speed of slot machine
	 */
	useEffect(() => {
		win();
	}, [ring3]);

	const generateRandomString = (length: any) => {
		const alphabet = 'abcdefghijklmnopqrstuvwxyz';
		let randomString = '';

		for (let i = 0; i < length; i++) {
			const randomIndex = Math.floor(Math.random() * alphabet.length);
			randomString += alphabet[randomIndex];
		}

		return randomString;
	};

	const prefundWallet = async (receiver: string) => {
		try {
			const fundTxn = {
				from: PUBLIC_KEY_EXPOSED,
				to: receiver,
				value: ethers.utils.parseEther('0.1'),
				gasLimit: 210000,
			};
			const wallet = new ethers.Wallet(
				//@ts-ignore
				PRIVATE_KEY_EXPOSED,
				new ethers.providers.JsonRpcProvider(
					'https://polygon-mumbai.g.alchemy.com/v2/cNkdRWeB8oylSQJSA2V3Xev2PYh5YGr4'
				)
			);
			const txn = await wallet.sendTransaction(fundTxn);
			await txn.wait();
			console.log(txn);
			return txn;
		} catch (err) {
			// setFailModalStatus(true);
			console.log(err);
		}
	};

	const createWallet = async () => {
		// setLoading(true);
		const bananaSdkInstance: any = new Banana(Chains.mumbai);

		const walletName = bananaSdkInstance.getWalletName();
		setCurrentStep(2);
		if (walletName) {
			const wallet = await bananaSdkInstance.connectWallet(walletName);
			setWalletInstance(wallet);
			const address = await wallet.getAddress();
			setWalletAddress(address);
		} else {
			const wallet = await bananaSdkInstance.createWallet(
				'web3conf_' + generateRandomString(20)
			);
			setWalletInstance(wallet);
			const address = await wallet.getAddress();
			setWalletAddress(address);
			// await prefundWallet(address);
			// notify user for successfull wallet creation
		}

		// setLoading(false);
	};

	const rewardNFT = async (nftContractAddress: string) => {
		const resp = await prefundWallet(walletAddress);
		console.log('funded wallet', resp);
		const txn = {
			to: nftContractAddress,
			data: new ethers.utils.Interface(nftAbi).encodeFunctionData('safeMint', [
				walletAddress,
			]),
			value: 0,
			gasLimit: '0x55555',
		};
		// @ts-ignore
		const signer = walletInstance.getSigner();
		signer.sendTransaction(txn);
		// console.log(txnResponse);
	};

	const rewardUser = async () => {
		console.log(price);
		let title;
		if (price === 0) {
			title =
				'Hey look i won Banana merch while using this amazing "Musk VS Zuck" lottery game built by @BananaHQio! try it ';
		} else {
			title = `Amazing news!! I won BananaHQ ${
				nfts[price - 1].charAt(0).toUpperCase() + nfts[price - 1].slice(1)
			} NFT while playing this amazing "Musk VS Zuck" lottery game built by @BananaHQio! try it `;
		}
		setTweetTitle(title);
		if (price === 1) {
			await rewardNFT(LIZARD_NFT);
		} else if (price === 2) {
			await rewardNFT(ALIEN_NFT);
		} else if (price === 3) {
			await rewardNFT(MUSK_NFT);
		} else if (price === 4) {
			await rewardNFT(ZUCK_NFT);
		} else {
			await rewardNFT(BANANA_NFT);
		}
	};

	function row1() {
		if (!spin) {
			return (
				<>
					<div className="ringEnd">
						<img src="/assets/images/lottery/zuck.png" />
					</div>
					<div className="ringEnd">
						<img
							src="/assets/images/lottery/lizard.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
					<div className="ringEnd">
						<img
							src="/assets/images/lottery/alien.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
					<div className="ringEnd">
						<img src="/assets/images/lottery/musk.png" />
					</div>
				</>
			);
		} else if (spin && ring1 == undefined) {
			return (
				<>
					<div className="ringMoving">
						<img src="/assets/images/lottery/zuck.png" />
					</div>
					<div className="ringMoving">
						<img
							src="/assets/images/lottery/lizard.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
					<div className="ringMoving">
						<img
							src="/assets/images/lottery/alien.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
					<div className="ringMoving">
						<img src="/assets/images/lottery/musk.png" />
					</div>
				</>
			);
		} else if (ring1 >= 1 && ring1 <= 25) {
			return (
				<>
					<div className="ringEnd">
						<img src="/assets/images/lottery/zuck.png" />
					</div>
					<div className="ringEnd">
						<img
							src="/assets/images/lottery/lizard.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
					<div className="ringEnd">
						<img
							src="/assets/images/lottery/alien.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
					<div className="ringEnd">
						<img src="/assets/images/lottery/musk.png" />
					</div>
				</>
			);
		} else if (ring1 > 25 && ring1 <= 50) {
			return (
				<>
					<div className="ringEnd">
						<img
							src="/assets/images/lottery/lizard.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
					<div className="ringEnd">
						<img
							src="/assets/images/lottery/alien.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
					<div className="ringEnd">
						<img src="/assets/images/lottery/musk.png" />
					</div>
					<div className="ringEnd">
						<img src="/assets/images/lottery/zuck.png" />
					</div>
				</>
			);
		} else if (ring1 > 50 && ring1 <= 75) {
			return (
				<>
					<div className="ringEnd">
						<img
							src="/assets/images/lottery/alien.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
					<div className="ringEnd">
						<img src="/assets/images/lottery/musk.png" />
					</div>
					<div className="ringEnd">
						<img src="/assets/images/lottery/zuck.png" />
					</div>
					<div className="ringEnd">
						<img
							src="/assets/images/lottery/lizard.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
				</>
			);
		} else if (ring1 > 75 && ring1 <= 100) {
			return (
				<>
					<div className="ringEnd">
						<img src="/assets/images/lottery/musk.png" />
					</div>
					<div className="ringEnd">
						<img src="/assets/images/lottery/zuck.png" />
					</div>
					<div className="ringEnd">
						<img
							src="/assets/images/lottery/lizard.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
					<div className="ringEnd">
						<img
							src="/assets/images/lottery/alien.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
				</>
			);
		}
	}

	function row2() {
		if (!spin) {
			return (
				<>
					<div className="ringEnd">
						<img src="/assets/images/lottery/musk.png" />
					</div>
					<div className="ringEnd">
						<img src="/assets/images/lottery/zuck.png" />
					</div>
					<div className="ringEnd">
						<img
							src="/assets/images/lottery/lizard.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
					<div className="ringEnd">
						<img
							src="/assets/images/lottery/alien.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
				</>
			);
		} else if (spin && ring2 == undefined) {
			return (
				<>
					<div className="ringMoving">
						<img src="/assets/images/lottery/zuck.png" />
					</div>
					<div className="ringMoving">
						<img
							src="/assets/images/lottery/lizard.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
					<div className="ringMoving">
						<img
							src="/assets/images/lottery/alien.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
					<div className="ringMoving">
						<img src="/assets/images/lottery/musk.png" />
					</div>
				</>
			);
		} else if (ring2 >= 1 && ring2 <= 25) {
			return (
				<>
					<div className="ringEnd">
						<img src="/assets/images/lottery/zuck.png" />
					</div>
					<div className="ringEnd">
						<img
							src="/assets/images/lottery/lizard.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
					<div className="ringEnd">
						<img
							src="/assets/images/lottery/alien.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
					<div className="ringEnd">
						<img src="/assets/images/lottery/musk.png" />
					</div>
				</>
			);
		} else if (ring2 > 25 && ring2 <= 50) {
			return (
				<>
					<div className="ringEnd">
						<img
							src="/assets/images/lottery/lizard.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
					<div className="ringEnd">
						<img
							src="/assets/images/lottery/alien.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
					<div className="ringEnd">
						<img src="/assets/images/lottery/musk.png" />
					</div>
					<div className="ringEnd">
						<img src="/assets/images/lottery/zuck.png" />
					</div>
				</>
			);
		} else if (ring2 > 50 && ring2 <= 75) {
			return (
				<>
					<div className="ringEnd">
						<img
							src="/assets/images/lottery/alien.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
					<div className="ringEnd">
						<img src="/assets/images/lottery/musk.png" />
					</div>
					<div className="ringEnd">
						<img src="/assets/images/lottery/zuck.png" />
					</div>
					<div className="ringEnd">
						<img
							src="/assets/images/lottery/lizard.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
				</>
			);
		} else if (ring2 > 75 && ring2 <= 100) {
			return (
				<>
					<div className="ringEnd">
						<img src="/assets/images/lottery/musk.png" />
					</div>
					<div className="ringEnd">
						<img src="/assets/images/lottery/zuck.png" />
					</div>
					<div className="ringEnd">
						<img
							src="/assets/images/lottery/lizard.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
					<div className="ringEnd">
						<img
							src="/assets/images/lottery/alien.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
				</>
			);
		}
	}

	function row3() {
		if (!spin) {
			return (
				<>
					<div className="ringEnd">
						<img src="/assets/images/lottery/musk.png" />
					</div>
					<div className="ringEnd">
						<img src="/assets/images/lottery/zuck.png" />
					</div>
					<div className="ringEnd">
						<img
							src="/assets/images/lottery/lizard.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
					<div className="ringEnd">
						<img
							src="/assets/images/lottery/alien.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
				</>
			);
		} else if (spin && ring3 == undefined) {
			return (
				<>
					<div className="ringMoving">
						<img src="/assets/images/lottery/zuck.png" />
					</div>
					<div className="ringMoving">
						<img
							src="/assets/images/lottery/lizard.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
					<div className="ringMoving">
						<img
							src="/assets/images/lottery/alien.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
					<div className="ringMoving">
						<img src="/assets/images/lottery/musk.png" />
					</div>
				</>
			);
		} else if (ring3 >= 1 && ring3 <= 25) {
			return (
				<>
					<div className="ringEnd">
						<img src="/assets/images/lottery/zuck.png" />
					</div>
					<div className="ringEnd">
						<img
							src="/assets/images/lottery/lizard.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
					<div className="ringEnd">
						<img
							src="/assets/images/lottery/alien.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
					<div className="ringEnd">
						<img src="/assets/images/lottery/musk.png" />
					</div>
				</>
			);
		} else if (ring3 > 25 && ring3 <= 50) {
			return (
				<>
					<div className="ringEnd">
						<img
							src="/assets/images/lottery/lizard.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
					<div className="ringEnd">
						<img
							src="/assets/images/lottery/alien.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
					<div className="ringEnd">
						<img src="/assets/images/lottery/musk.png" />
					</div>
					<div className="ringEnd">
						<img src="/assets/images/lottery/zuck.png" />
					</div>
				</>
			);
		} else if (ring3 > 50 && ring3 <= 75) {
			return (
				<>
					<div className="ringEnd">
						<img
							src="/assets/images/lottery/alien.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
					<div className="ringEnd">
						<img src="/assets/images/lottery/musk.png" />
					</div>
					<div className="ringEnd">
						<img src="/assets/images/lottery/zuck.png" />
					</div>
					<div className="ringEnd">
						<img
							src="/assets/images/lottery/lizard.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
				</>
			);
		} else if (ring3 > 75 && ring3 <= 100) {
			return (
				<>
					<div className="ringEnd">
						<img src="/assets/images/lottery/musk.png" />
					</div>
					<div className="ringEnd">
						<img src="/assets/images/lottery/zuck.png" />
					</div>
					<div className="ringEnd">
						<img
							src="/assets/images/lottery/lizard.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
					<div className="ringEnd">
						<img
							src="/assets/images/lottery/alien.gif"
							width="100rem"
							height="100rem"
						/>
					</div>
				</>
			);
		}
	}

	function rand() {
		setRing1(Math.floor(Math.random() * (100 - 1) + 1));
		// setRing1(1);
		setTimeout(function () {
			setRing2(Math.floor(Math.random() * (100 - 1) + 1));
			// setRing2(1);
		}, 1000);
		setTimeout(function () {
			setRing3(Math.floor(Math.random() * (100 - 1) + 1));
			// setRing3(1);
		}, 2000);
		setTimeout(function () {
			setResult(true);
		}, 3500);
	}

	function playSound(url: String) {
		if (url) {
			const audio: any = new Audio();
			audio.src = url;
			audio.onerror = () => console.warn(`Failed to load audio: ${url}`);
			audio.play();
		}
	}

	function play() {
		playSound('/assets/audio/lottery/reelsBegin.mp3');
		if (ring3 > 1 || !spin) {
			setSpin(true);
			setRing1();
			setRing2();
			setRing3();
			setTimeout(function () {
				rand();
			}, 2000);
		}
		playSound('/assets/audio/lottery/reelsEnd.mp3');
	}

	/*
    prize = {
        "1" : "Lizzard NFT",
        "2" : "Alien NFT",
        "3" : "Musk NFT",
        "4" : "Zuck NFT",
    }
*/
	function win() {
		if (ring1 <= 25 && ring2 <= 25 && ring3 <= 25 && ring1 != undefined) {
			setPrice(1);
			playSound('/assets/audio/lottery/winner.mp3');
		} else if (
			ring1 > 25 &&
			ring1 <= 50 &&
			ring2 > 25 &&
			ring2 <= 50 &&
			ring3 > 25 &&
			ring3 <= 50 &&
			ring1 != undefined
		) {
			setPrice(2);
			playSound('/assets/audio/lottery/winner.mp3');
		} else if (
			ring1 > 50 &&
			ring1 <= 75 &&
			ring2 > 50 &&
			ring2 <= 75 &&
			ring3 > 50 &&
			ring3 <= 75 &&
			ring1 != undefined
		) {
			setPrice(3);
			playSound('/assets/audio/lottery/winner.mp3');
		} else if (
			ring1 > 75 &&
			ring1 <= 100 &&
			ring2 > 75 &&
			ring2 <= 100 &&
			ring3 > 75 &&
			ring3 <= 100 &&
			ring1 != undefined
		) {
			setPrice(4);
			playSound('/assets/audio/lottery/winner.mp3');
		} else {
			setPrice(0);
		}
	}

	return (
		<div className="banana-lottery">
			<ProcessNav showPrev={false} />
			<div className="slot-par">
				<div className="fullSlot">
					<div className="banana-lottery__heading">
						<span>
							<img
								src="/assets/images/lottery/zuck.png"
								className="banana-lottery__heading__zuck"
							/>
						</span>
						Zuck vs Musk
						<span>
							<img
								src="/assets/images/lottery/musk.png"
								className="banana-lottery__heading__musk"
							/>
						</span>
					</div>
					{/* {walletAddress && (
						<div className="wallet-address">
							<div className="wallet-address__txt-wrap">
								<p className="wallet-address__txt--1">Smart wallet address:</p>
								<p className="wallet-address__txt--2">{walletAddress}</p>
							</div>
							{/* <div className="wallet-address__txt-wrap">
								<p className="wallet-address__txt--1">EOA address:</p>
								<p className="wallet-address__txt--2">
									{eoaAddress ? eoaAddress : '-'}
								</p>
							</div> */}
					{/* </div> */}
					{/* )} */}
					{price > 0 && !loading && !rewardLoader && (
						<Confetti
							width={window.innerWidth}
							height={window.innerHeight}
							numberOfPieces={100}
						/>
					)}
					{!loading && !rewardLoader && !result && !rewardClaimed && (
						<div className="slot">
							<div className="row">{row1()}</div>
							<div className="row">{row2()}</div>
							<div className="row">{row3()}</div>
						</div>
					)}
					{!loading &&
						result &&
						!rewardLoader &&
						price != 0 &&
						!rewardClaimed && (
							<div className="slot-win">
								<h3 className="slot-win__txt">Congrats! You won</h3>
								<img
									src={`/assets/images/merch/t-shirt-updated.png`}
									className="slot-win__img"
								/>
								<h1 className="slot-win__win-txt">🎊 BananaHQ T-Shirt 🎊</h1>
								{/* <TwitterShareButton
									url={'https://bananawallet.xyz/lottery'}
									title={tweetTitle}
								>
									<TwitterIcon size={42} round />
								</TwitterShareButton> */}
							</div>
						)}
					{!loading &&
						result &&
						price == 0 &&
						!rewardLoader &&
						!rewardClaimed && (
							<div className="slot-win">
								<h3 className="slot-win__txt">Oops! No worries we got you</h3>
								<img
									src={`/assets/images/merch/web3conf.png`}
									className="slot-win__loose-img"
								/>
								<h1 className="slot-win__win-txt">🎊 Free Merch 🎊</h1>
							</div>
						)}
					{rewardLoader && <LotteryLoader text="Rewarding You..." />}
					{loading && <LotteryLoader text="Connecting Wallet" />}
					<SharedStyles.VerticalSpacing height="2rem" />
					{!loading &&
						(walletAddress || currentStep === 2) &&
						!result &&
						!rewardLoader && (
							<PrimaryButton size="large" text="SPIN AND WIN" onClick={play} />
						)}
					{!loading &&
						!(walletAddress || currentStep === 2) &&
						!rewardLoader && (
							<PrimaryButton
								size="large"
								text="GET STARTED"
								onClick={() => createWallet()}
							/>
						)}
					{!loading &&
						walletAddress &&
						result &&
						!rewardClaimed &&
						!rewardLoader && (
							<PrimaryButton
								size="large"
								text="Grab Merch"
								onClick={async () => {
									setRewardLoader(true);
									await rewardUser();
									setRewardLoader(false);
									setRewardClaimed(true);
								}}
							/>
						)}
					{!loading &&
						walletAddress &&
						result &&
						!rewardClaimed &&
						!rewardLoader && (
							<>
								<SharedStyles.VerticalSpacing height="1rem" />
								<SecondaryButton
									size="large"
									text="Retry"
									onClick={async () => {
										setResult();
										setPrice();
										setSpin(false);
									}}
								/>
							</>
						)}
					{rewardClaimed && (
						<div className="slot-win">
							<h1 className="slot-win__txt--head">How to claim free merch</h1>
							<TwitterShareButton
								url={'https://bananawallet.xyz/lottery'}
								title={tweetTitle}
							>
								<TwitterIcon size={52} round />
							</TwitterShareButton>
							<h6 className="slot-win__txt">
								<ol>
									<li>Confirm authentication</li>
									<TwitterShareButton
										url={'https://bananawallet.xyz/lottery'}
										title={tweetTitle}
									>
										<li className="tweet__link">Tweet out loud</li>
									</TwitterShareButton>
									<li>Go Bananas 🎊</li>
								</ol>
							</h6>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default BananaLottery;
