/* eslint-disable import/no-extraneous-dependencies */
import { styled } from 'goober';

export const styles = {
	ParentWrapper: styled('div')`
		background-color: #111111;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		min-height: 100vh;
	`,
	Wrapper: styled('div')`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 30%;
		padding: 3.125rem 1rem;
		background-color: #1b1b1b;
		color: #ffffff;
		@media (max-width: 1024px) {
			width: 50%;
		}
		@media (max-width: 768px) {
			width: 90%;
		}
	`,
	TextWrap: styled('div')`
		text-align: center;
		width: 100%;
	`,
	ButtonWrap: styled('div')`
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: center;
		width: 100%;
	`,
	Header: styled('div')`
		font-size: 3rem;
		font-family: 'Urbanist-Bold';
		color: #fbd025;
		margin-top: 2.25rem;
		margin-bottom: 0.313rem;
	`,

	SubHeader: styled('div')`
		font-size: 1.125rem;
		font-family: 'Urbanist-Medium';
		color: #b4b4b4;
		margin-bottom: 1.5rem;
	`,

	HeaderWrap: styled('div')`
		text-align: center;
	`,

	ContentWrap: styled('div')`
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
	`,
	FieldWrapper: styled('div')`
		margin: 1.5rem 0;
	`,
	FieldName: styled('div')`
		color: #b4b4b4;
		font-size: 1.25rem;
		font-family: 'Urbanist-Medium';
		margin-bottom: 0.75rem;
		text-align: left;
	`,

	FieldValue: styled('div')`
		color: #ffffff;
		font-size: 1rem;
		font-family: 'Urbanist-SemiBold';
		margin-bottom: 1.5rem;
		word-wrap: break-word;
	`,
	RowFlex: styled('div')`
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin: 1.5rem 0;
	`,
	FieldRowName: styled('div')`
		color: #b4b4b4;
		font-size: 1.25rem;
		font-family: 'Urbanist-Medium';
	`,

	FieldRowValue: styled('div')`
		color: #ffffff;
		font-size: 1rem;
		font-family: 'Urbanist-SemiBold';
		word-wrap: break-word;
		max-width: 80%;
	`,
	ButtonDivWrap: styled('div')`
		margin-top: 1.2rem;
		width: 100%;
	`,
	ButtonDiv: styled('div')`
		margin-top: 1rem;
		width: 100%;
	`,
};
