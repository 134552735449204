/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { Button } from 'antd';
import './PrimaryButton.scss';

type primaryButtonPropTypes = {
	text: string;
	size: 'large' | 'middle' | 'small';
	onClick: () => void;
	htmlType?: 'submit' | 'button';
	className?: 'string';
};

const PrimaryButton = (props: primaryButtonPropTypes) => {
	return (
		<Button
			size={props.size}
			type="primary"
			className={`primary-btn-${props.size}`}
			onClick={props.onClick}
			htmlType={props.htmlType}
		>
			<div className="primary-btn-txt-div">{props.text}</div>
		</Button>
	);
};

export default PrimaryButton;
