import { v4 } from 'uuid';
import { BANANA_APP_URL } from '../constants/routes';
import { buildUrl } from './urlBuilder';

export type DeviceAdditionData = {
	deviceAdditionId: string;
	deviceAdditionUrl: string;
};

export const getDeviceAdditionData = (): DeviceAdditionData => {
	const additionId = v4();
	const deviceAdditionUrl = buildUrl(BANANA_APP_URL, {
		path: ['wallet', 'complete-add-device', additionId],
	});

	return {
		deviceAdditionId: additionId,
		deviceAdditionUrl,
	} as DeviceAdditionData;
};
