/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import Axios from 'axios';
import {
	CONNECT_ROUTE,
	GET_USER_CRED_ROUTE,
	CHECK_WALLET_EXIST_ROUTE,
	SET_WALLET_METADATA_ROUTE,
	SET_MESSAGE_CONFIRMATION_ROUTE,
	SET_TXN_CONFIRMATION_ROUTE,
} from '../constants/routes';
import {
	ISessionUsername,
	IWalletMetaData,
	IUserMessageConfigmResp,
} from '../types/controllerTypes';

export const transportUsername = async ({
	sessionId,
	walletUniqueIdentifier,
	isMobile,
}: ISessionUsername): Promise<boolean> => {
	if (isMobile) {
		window.open('banana-app://banana&walletname=' + walletUniqueIdentifier);
		return true;
	}
	const inputRes = await Axios({
		url: process.env.REACT_APP_SERVER_URL + CONNECT_ROUTE,
		method: 'POST',
		data: {
			sessionId,
			username: walletUniqueIdentifier,
		},
	});

	return inputRes.data.success === true;
};

export const transportIUserMessageConfigmResponse = async ({
	sessionId,
	signature,
	isMobile,
}: IUserMessageConfigmResp): Promise<boolean> => {
	if (isMobile) {
		//! for signature
		window.open('banana-app://banana&message=' + signature);
		return true;
	}

	const messageConfirmationSavingResp = await Axios({
		url: process.env.REACT_APP_SERVER_URL + SET_MESSAGE_CONFIRMATION_ROUTE,
		method: 'POST',
		data: {
			sessionId,
			signature,
		},
	});

	return messageConfirmationSavingResp.data.success === true;
};

export const transportUserTransactionConfirmationResponse = async ({
	sessionId,
	signature,
	isMobile,
}: IUserMessageConfigmResp): Promise<boolean> => {
	if (isMobile) {
		//! for signature
		window.open('banana-app://banana&txn=' + signature);
		return true;
	}
	const messageConfirmationSavingResp = await Axios({
		url: process.env.REACT_APP_SERVER_URL + SET_TXN_CONFIRMATION_ROUTE,
		method: 'POST',
		data: {
			sessionId,
			signature,
		},
	});

	return messageConfirmationSavingResp.data.success === true;
};

export const getMetaDataForUsername = async (
	uniqueIdentifier: string
): Promise<IWalletMetaData> => {
	const WalletMetaDataResp = await Axios({
		url: process.env.REACT_APP_SERVER_URL + GET_USER_CRED_ROUTE,
		method: 'GET',
		params: {
			uniqueIdentifier,
		},
	});

	if (WalletMetaDataResp.data.data === '') return {} as IWalletMetaData;

	const walletPublicData = JSON.parse(WalletMetaDataResp.data.data);
	return walletPublicData;
};

export const checkWalletExist = async (
	uniqueIdentifier: string
): Promise<boolean> => {
	const resp = await Axios({
		url: process.env.REACT_APP_SERVER_URL + CHECK_WALLET_EXIST_ROUTE,
		method: 'POST',
		data: {
			walletName: uniqueIdentifier,
		},
	});
	return resp.data.isUnique === false;
};

export const setWalletMetaData = async (
	userIdentifier: string,
	walletMetaData: Partial<IWalletMetaData>
) => {
	try {
		const updateUserCredentialStatus = await Axios({
			url: process.env.REACT_APP_SERVER_URL + SET_WALLET_METADATA_ROUTE,
			method: 'POST',
			data: {
				uniqueIdentifier: userIdentifier,
				userCredentials: walletMetaData,
			},
		});

		if (updateUserCredentialStatus)
			return {
				success: true,
			};

		return {
			success: false,
		};
	} catch (err) {
		console.log(err);
		throw err;
	}
};

// under discusssion
// export const deviceAdditionStatus = async (additionId: string) => {

// }
// export const
