export const downloadImageFromBase64Url = (
	url: string,
	fileName: string
): void => {
	// eslint-disable-next-line @typescript-eslint/no-floating-promises
	fetch(url)
		.then((response) => response.blob())
		.then((blob) => {
			// Create a temporary anchor element
			const link = document.createElement('a');
			link.href = URL.createObjectURL(blob);
			link.download = fileName;

			// Programmatically click the link to trigger the download
			link.click();

			// Clean up the temporary anchor element
			URL.revokeObjectURL(link.href);
			link.remove();
		});
};
