/* eslint-disable import/no-extraneous-dependencies */
import { styled } from 'goober';

export const styles = {
	ParentWrapper: styled('div')`
		background-color: #111111;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100vh;
	`,
	Container: styled('div')`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100vh;
		width: 30%;
		padding: 0 2rem;
		background-color: #1b1b1b;
		color: #ffffff;
		@media (max-width: 1024px) {
			width: 100%;
		}
	`,
	Img: styled('img')`
		width: 11.563rem;
	`,
	Title: styled('p')`
		font-family: 'Urbanist-SemiBold';
		font-size: 1.5rem;
		width: 80%;
		text-align: center;
		margin-top: 1.5rem;
	`,
	Txt: styled('p')`
		font-family: 'Urbanist-Regular';
		font-size: 1.125rem;
		text-align: center;
		color: #bcbcbc;
	`,
	WADiv: styled('div')`
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		border: 1px solid #575757;
		border-radius: 8px;
		padding: 0.625rem 0.5rem;
		width: 100%;
	`,
	CopyBtn: styled('button')`
		background: #ffd42b;
		border-radius: 22px;
		padding: 0.45rem;
		border: none;
		color: #111111;
		cursor: pointer;
	`,
	WalletAddress: styled('p')`
		font-family: 'Urbanist-Regular';
		font-size: 0.75rem;
		margin: 0;
	`,
};
