/* eslint-disable import/no-extraneous-dependencies */
import { styled } from 'goober';

export const styles = {
	Wrapper: styled('div')`
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
	`,
	Sub: styled('p')`
		font-family: 'Urbanist-SemiBold';
		font-size: 1.5rem;
		text-align: center;
	`,
};
