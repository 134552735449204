/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable */
import './Connect.scss';
import React, { useEffect, useContext, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import {
	createWallet,
	IWalletCreatrOrConnectResp,
} from '../../wallet/createWallet';
import { connectWallet, IConnectWalletResp } from '../../wallet/connectWallet';
import WalletLoader from '../WalletLoader/WalletLoader';
import { WalletContext } from '../../context/walletContextProvider';
import { WalletContextType } from '../../types/walletContext';
import { styles } from './styles';
import Blockies from 'react-blockies';
import CustomWalletInput from '../shared/CustomWalletInput/CustomWalletInput';
import { fetchWalletDetails } from '../../utils/fetchWalletDetails';
import { IWalletMetaData } from '../../types/controllerTypes';
import { BiUser } from 'react-icons/bi';
import { IWalletPresentableData } from '../../hooks/useFetchWalletMeta';
import { checkWalletExist } from '../../services/controller';
import { FloatButton, message } from 'antd';
import { IoMdAdd } from 'react-icons/io';
import { IoExit } from 'react-icons/io5';
import { AiOutlinePlus } from 'react-icons/ai'

const Connect = () => {
	const deviceWidth = window.screen.width;
	const navigate = useNavigate();
	const { connectId } = useParams();
	const [sessionId, setSessionId] = useState<string | undefined>('');
	const [username, setUserName] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isUserPossesWallet, setIsUserPossesWallet] = useState<boolean>(false);
	const [walletDetails, setWalletDetails] =
		useState<Partial<IWalletMetaData>[]>();
	const queryParameters = new URLSearchParams(window.location.search);
	const isMobile = queryParameters.get('isMobile') === 'true';
	const deepLink = queryParameters.get('deepLink');
	const { setStatus } = useContext(WalletContext) as WalletContextType;

	const fetchWalletPresentableDetails = async () => {
		const walletPresentableDetails: IWalletPresentableData[] =
			await fetchWalletDetails();

		if (walletPresentableDetails.length > 0) {
			setIsUserPossesWallet(true);
		}
		setWalletDetails(walletPresentableDetails);
	};

	useEffect(() => {
		setSessionId(connectId);
		fetchWalletPresentableDetails();
	}, []);

	const connectWalletForUser = async () => {
		if (!username) {
			toast.error("Username can't be empty");
			return;
		}

		if (!/^[a-zA-Z0-9]+$/.test(username)) {
			toast.error('Only letters and numbers are allowed');
			return;
		}

		setIsLoading(true);
		const resp: IConnectWalletResp = await connectWallet(
			{ walletUniqueIdentifier: username, inCookie: false },
			sessionId || '',
			deepLink || ''
		);

		if (resp.isConnected) {
			setTimeout(() => {
				toast.success('Successfully connected your wallet Redirecting!');
			}, 500);

			setIsLoading(false);

			setTimeout(() => {
				window.close();
			}, 3000);
		} else {
			setTimeout(() => {
				toast.error("You don't have access to this wallet");
			}, 500);

			setIsLoading(false);
		}
	};

	const createWalletForUser = async () => {
		if (!username) {
			toast.error("Username can't be empty");
			return;
		}

		if (!/^[a-zA-Z0-9]+$/.test(username)) {
			toast.error('Only letters and numbers are allowed');
			return;
		}

		setIsLoading(true);

		const isWalletExist = await checkWalletExist(username);

		if (isWalletExist) {
			connectWalletForUser();
			return;
		}

		const resp: IWalletCreatrOrConnectResp = await createWallet(
			username,
			sessionId || '',
			isMobile,
			deepLink || ''
		);

		if (resp.isWalletConnectedOrCreated) {
			if (resp.isConnect) {
				toast.success('Wallet connected !');
			}

			setStatus(true);
			// Once successful navigating user
			navigate('/success');
			setIsLoading(false);
		} else {
			if (!resp.isCreate && !resp.isConnect && !resp.isError) {
				toast.error("Can't connect or create wallet!");
			}
			if (!resp.isCreate && !resp.isConnect && !resp.isError) {
				toast.error("Can't connect or create wallet due to some error");
			}
			setIsLoading(false);
		}
	};

	const connectWalletInCookieForUser = async (walletname: string) => {
		setIsLoading(true);
		const resp: IConnectWalletResp = await connectWallet(
			{ walletUniqueIdentifier: walletname, inCookie: true },
			sessionId || '',
			deepLink || ''
		);

		if (resp.isConnected) {
			toast.error('Successfully connected your wallet');
		} else {
			toast.error('Some error occured');
		}
		setIsLoading(false);
		window.close();
	};

	const addRef :any= useRef();
	const exitRef :any= useRef();
	const floatButtonRef :any= useRef();
	const addTooltipRef :any= useRef();
	const exitTooltipRef :any= useRef();
	const [menuOpen, setMenuOpen] = useState(false);

	const floatButtonHandler = () => {
		console.log(addRef.current);
		console.log(exitRef.current);
		if(!menuOpen) {
			floatButtonRef.current!.style.rotate = '45deg';
			addRef!.current!.style.translate = '0 -8rem';
			exitRef!.current!.style.translate = '0 -4rem';
			addTooltipRef!.current!.style.display = 'block';
			exitTooltipRef!.current!.style.display = 'block';
			setMenuOpen(true);
		} else {
			floatButtonRef.current!.style.rotate = '0deg';
			addRef!.current!.style.translate = '0 0';
			exitRef!.current!.style.translate = '0 0';
			addTooltipRef!.current!.style.display = 'none';
			exitTooltipRef!.current!.style.display = 'none';
			setMenuOpen(false);
		}
	}

	const floatButtonParStyle : any = {
		position: 'fixed',
		bottom: '2.5rem',
		right: '2.5rem',
		zIndex: 100,
		transition: 'all 0.4s ease-in-out',
	}
	const floatOptionStyle: any = {
		position: 'fixed',
		bottom: '2.5rem',
		right: '2.5rem',
		transition: 'all 0.4s ease-in-out',
		display: 'flex',
		alignItems: 'center',
	}

	return (
		<div>
			<WalletLoader text="Creating / Connecting wallet.." isLoading={isLoading}>
				<Toaster />
				<div className="connect-logo">
					<img src="/assets/images/Logos/banana-logo.png" alt="Logo" /> <br />
				</div>
				<h3 className="connect-logo-heading"> Banana SDK</h3>
				<styles.ParentWrapper>
					<styles.Wrapper>
						{isUserPossesWallet && (
							<styles.Header>Existing Wallet</styles.Header>
						)}
						<styles.Spacing />

						{isUserPossesWallet &&
							walletDetails?.map((wallet) => {
								return (
									<styles.WalletDetails
										onClick={() =>
											connectWalletInCookieForUser(wallet.username || '')
										}
										key={wallet.encodedId}
									>
										<styles.WalletText key={wallet.encodedId}>
											<styles.WalletUsername key={wallet.encodedId}>
												#{wallet.username}
											</styles.WalletUsername>
											<styles.WalletAddress key={wallet.encodedId}>
												{wallet.walletAddress}
											</styles.WalletAddress>
										</styles.WalletText>
										<Blockies
											seed={
												wallet.walletAddress ? wallet.walletAddress : 'random'
											}
											size={deviceWidth > 780 ? 10 : 7}
											key={wallet.encodedId}
										/>
									</styles.WalletDetails>
								);
							})}

						{isUserPossesWallet && <styles.Header>OR</styles.Header>}

						<CustomWalletInput
							className="snup"
							label="Username"
							icon={<BiUser className="cwi-icon" />}
							placeholder="Type your username here"
							onChange={(e: any) => setUserName(e.target.value)}
							buttonId="signup-btn"
						/>
						<styles.PrimaryButton
							onClick={() => {
								createWalletForUser();
							}}
							id="signup-btn"
						>
							Sign up
						</styles.PrimaryButton>
						<styles.SecondaryButton onClick={() => connectWalletForUser()}>
							Log In
						</styles.SecondaryButton>
						<styles.OrWrapper>
							<styles.HorizontalLine />
							<styles.Text>Or</styles.Text>
							<styles.HorizontalLine />
						</styles.OrWrapper>
						<styles.ButtonPar>
							<styles.TransparentButton
								onClick={() => navigate('/wallet/init-add-device')}
							>
								<styles.ButtonWrapper>
									<styles.ExitIcon />
									<styles.ButtonTxt>Add new Device</styles.ButtonTxt>
								</styles.ButtonWrapper>
							</styles.TransparentButton>
							<styles.ButtonSpacing />
							<styles.TransparentButton>
								<styles.ButtonWrapper>
									<styles.PlusIcon />
									<styles.ButtonTxt>Recover Account</styles.ButtonTxt>
								</styles.ButtonWrapper>
							</styles.TransparentButton>
							{
								window.innerWidth <= 768 &&
								<div style={{ position: 'fixed', right: '2rem', bottom: '2rem' }}>
									<div ref={floatButtonRef} style={floatButtonParStyle} onClick={floatButtonHandler} >
										<styles.FloatButton>
											<AiOutlinePlus size={30} />
										</styles.FloatButton>
									</div>
									<div ref={addRef} style={floatOptionStyle}>
										<p ref={addTooltipRef} style={{ display: 'none', margin: '0 0.8rem 0 0' }} >Add new Device</p>
										<styles.FloatOption className='float-option-add' onClick={() => navigate('/wallet/init-add-device')}>
											<IoMdAdd size={25} />
										</styles.FloatOption>
									</div>
									<div ref={exitRef} style={floatOptionStyle}>
										<p ref={exitTooltipRef} style={{ display: 'none', margin: '0 0.8rem 0 0' }} >Recover Account</p>
										<styles.FloatOption className='float-option-recover' onClick={() => message.success('Recover Account')}>
											<IoExit size={25} />
										</styles.FloatOption>
									</div>
								</div>
							}
						</styles.ButtonPar>
					</styles.Wrapper>
				</styles.ParentWrapper>
			</WalletLoader>
		</div>
	);
};

export default Connect;
