export const CONNECT_ROUTE = '/api/v1/connect';
export const GET_USER_CRED_ROUTE = '/api/v1/get-user-credentials';
export const CHECK_WALLET_EXIST_ROUTE = '/api/v1/check-walletname-exists';
export const SET_WALLET_METADATA_ROUTE = '/api/v1/add-user-credentials';
export const SET_MESSAGE_CONFIRMATION_ROUTE =
	'/api/v1/message-sign-confirmation';
export const SET_TXN_CONFIRMATION_ROUTE =
	'/api/v1/transaction-sign-confirmation';
export const ADD_NEW_DEVICE = '/api/v1/add-device-config';
export const BANANA_APP_URL = 'http://localhost:3001';
