/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import './App.scss';
import 'antd/dist/reset.css';
import { setup } from 'goober';
import { ConfigProvider, theme, Button, Card } from 'antd';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './components/LandingPage/LandingPage';
import Connect from './components/Connect/Connect';
import TxnConfirmation from './components/TxnConfirmation/TxnConfirmation';
import MessageSign from './components/MessageSign/MessageSign';
import WalletSuccess from './components/WalletSuccess/WalletSuccess';
import WalletLoader from './components/WalletLoader/WalletLoader';
import DeviceAdditionNewDeviceFlow from './components/MultiDevice/DeviceAdditionNewDeviceFlow/DeviceAddition';
import DeviceAdditionOldDeviceFlow from './components/MultiDevice/DeviceAdditionOldDeviceFlow/DeviceAddition';
import OldConnect from './components/OldConnect/OldConnect';
import { WalletMetaProvider } from './context/walletContextProvider';
import BananaLottery from './components/BananaLottery/BananaLottery';
const { darkAlgorithm } = theme;

setup(React.createElement);

function App() {
	return (
		<div className="App">
			<ConfigProvider
				theme={{ algorithm: darkAlgorithm, token: { colorPrimary: '#FFD42B' } }}
			>
				<WalletMetaProvider>
					<Router>
						<Routes>
							<Route path="/" element={<LandingPage />} />
							<Route path="/connect/:connectId" element={<OldConnect />} />
							<Route path="/wallet/:connectId" element={<Connect />} />
							<Route
								path="/wallet/transact/:transactionId"
								element={<TxnConfirmation />}
							/>
							<Route
								path="/wallet/sign/:signatureId"
								element={<MessageSign />}
							/>
							<Route
								path="/success"
								element={<WalletSuccess title="Wallet created successfully" />}
							/>
							<Route
								path="/loader"
								element={<WalletLoader text="loading..." isLoading={true} />}
							/>
							<Route
								path="/wallet/complete-add-device/:additionId"
								element={<DeviceAdditionNewDeviceFlow />}
							/>
							<Route
								path="/wallet/init-add-device"
								element={<DeviceAdditionOldDeviceFlow />}
							/>
							<Route
								path="/lottery"
								element={<BananaLottery />}
							/>
						</Routes>
					</Router>
				</WalletMetaProvider>
			</ConfigProvider>
		</div>
	);
}

export default App;
