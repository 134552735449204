/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { transportUsername, checkWalletExist } from '../services/controller';
import { setCookie, getCookie } from '../utils/cookie';
import { IWalletMetaData } from '../types/controllerTypes';
import { generateRandomString } from '../utils/randomMessageGenerator';
import { checkAuth } from './checkWallet';
import { ethers } from 'ethers';
import { getDeviceEncodedId } from '../utils/getDeviceEncodedId';

//! Handle cases for connect another wallet and wallet selection from list
export type IConnectWalletOptions = {
	walletUniqueIdentifier: string;
	inCookie: boolean;
};

export type IConnectWalletResp = {
	isConnected: boolean;
};

export const connectWallet = async (
	{ walletUniqueIdentifier, inCookie }: IConnectWalletOptions,
	sessionId: string,
	deepLink?: string
): Promise<IConnectWalletResp> => {
	//! check if wallet corresponding to exist in DB is inCookie is false
	let isTransported = false;
	if (inCookie) {
		try {
			isTransported = await transportUsername({
				sessionId,
				walletUniqueIdentifier,
			});

			const walletMeta: Partial<IWalletMetaData> = getCookie(
				walletUniqueIdentifier
			);

			if (deepLink) {
				const deepLinkUrl = `${deepLink}&q0=${walletMeta.q0}&q1=${walletMeta.q1}&encodedId=${walletMeta.encodedId}&username=${walletUniqueIdentifier}`;
				window.open(deepLinkUrl);
			}
		} catch (err) {
			isTransported = false;
		}
	} else {
		const isWalletExist = await checkWalletExist(walletUniqueIdentifier);
		//! fetch data and set cookie
		if (isWalletExist) {
			let WalletMetaData: Partial<IWalletMetaData>;
			try {
				WalletMetaData = await getDeviceEncodedId(walletUniqueIdentifier);

				const randomMessage = generateRandomString(30);
				const messageHash = ethers.utils.keccak256(
					ethers.utils.solidityPack(['string'], [randomMessage])
				);

				const isUserAuthorized = await checkAuth(
					randomMessage,
					messageHash,
					WalletMetaData.encodedId || '',
					[WalletMetaData.q0 || '', WalletMetaData.q1 || '']
				);

				if (isUserAuthorized) {
					isTransported = await transportUsername({
						sessionId,
						walletUniqueIdentifier,
					});

					if (deepLink) {
						const deepLinkUrl = `${deepLink}&q0=${WalletMetaData.q0}&q1=${WalletMetaData.q1}&encodedId=${WalletMetaData.encodedId}&username=${walletUniqueIdentifier}`;
						window.open(deepLinkUrl);
					}

					const walletIdentifier = getCookie('bananaUser');
					setCookie(
						'bananaUser',
						JSON.stringify([...walletIdentifier, walletUniqueIdentifier])
					);
					setCookie(walletUniqueIdentifier, JSON.stringify(WalletMetaData));
				}
			} catch (err) {
				isTransported = false;
			}
		} else {
			isTransported = false;
		}
	}

	return {
		isConnected: isTransported,
	};
};
