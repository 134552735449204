/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect, useState } from 'react';
import { styles } from './styles';
import ModalNav from '../shared/ModalNav/ModalNav';
import { SharedStyles } from '../shared/SharedStyle';
import { useParams } from 'react-router-dom';
import { getSignature } from '../../wallet/checkWallet';
import { getCookie } from '../../utils/cookie';
import { IWalletMetaData } from '../../types/controllerTypes';
import { transportUserTransactionConfirmationResponse } from '../../services/controller';
import toast, { Toaster } from 'react-hot-toast';
import { ethToUsd } from '../../services/ethToUsd';
import { valueToEth } from '../../utils/valueToEth';
import { getDeviceEncodedId } from '../../utils/getDeviceEncodedId';

//! commenting down USD values for now later on bring in chain native token based conversion
const TxnConfirmation = () => {
	const { transactionId } = useParams();
	const queryParameters = new URLSearchParams(window.location.search);

	const to = queryParameters.get('to') || '';
	const from = queryParameters.get('from') || '';
	const value = queryParameters.get('value') || '';
	const gas = queryParameters.get('gas') || '';
	const userOpHash = queryParameters.get('userOpHash') || '';
	const walletName = queryParameters.get('walletname') || '';
	const isMobile = queryParameters.get('isMobile') || '';

	// expecting a deeplink in the url
	const deepLink = queryParameters.get('deepLink');

	const [txnValue, setTxnValue] = useState<number>();
	const [gasValue, setGasValue] = useState<number>();

	const signTransaction = async () => {
		const walletMeta = await getDeviceEncodedId(walletName);

		let finalCombinedSignature = '';
		let isKeySymm = false;

		while (!isKeySymm) {
			const { isKeyHexSymm, newSignature } = await getSignature(
				userOpHash || '',
				walletMeta.encodedId || ''
			);
			isKeySymm = isKeyHexSymm;
			finalCombinedSignature = newSignature;
		}

		const signatureTransportationResp =
			await transportUserTransactionConfirmationResponse({
				sessionId: transactionId || '',
				signature: finalCombinedSignature,
				isMobile: isMobile === 'true',
			});

		// transporting signature via deeplink if deep link is there
		if (deepLink) {
			const deepLinkSignatureTransportUrl = `${deepLink}&signature=${finalCombinedSignature}}`;
			window.open(deepLinkSignatureTransportUrl);
		}

		if (signatureTransportationResp) {
			toast.success('Signed transaction redirecting!');
			setTimeout(() => {
				window.close();
			}, 5000);
		} else {
			toast.error('unable to sign message');
		}
	};

	const convertValueToUsd = async () => {
		const txnPriceInUsd: number = await ethToUsd(
			parseInt(value, 10) / 10 ** 18
		);
		const gasPriceInUsd: number = await ethToUsd(parseInt(gas, 10) / 10 ** 18);
		setTxnValue(txnPriceInUsd);
		setGasValue(gasPriceInUsd);
	};

	const closeWindow = async () => {
		const signatureTransportationResp =
			await transportUserTransactionConfirmationResponse({
				sessionId: transactionId || '',
				signature: 'cancell',
				isMobile: isMobile === 'true',
			});

		if (signatureTransportationResp) {
			toast.error('Transaction cancelled');
		} else {
			toast.error('Some error occured!! ');
		}

		setTimeout(() => {
			window.close();
		}, 3000);
	};

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		convertValueToUsd();
	}, []);

	return (
		<styles.ParentWrapper>
			<Toaster />
			<styles.Wrapper>
				<ModalNav title="Confirm" showIcon={false} />
				<styles.ContentWrap>
					<styles.TextWrap>
						<styles.HeaderWrap>
							<styles.Header>{valueToEth(value)} ETH</styles.Header>
							{/* <styles.SubHeader>{txnValue} USD</styles.SubHeader> */}
						</styles.HeaderWrap>
						<SharedStyles.Divider />
						<styles.FieldWrapper>
							<styles.FieldName>From</styles.FieldName>
							<styles.FieldValue>{from}</styles.FieldValue>
							<styles.FieldName>To</styles.FieldName>
							<styles.FieldValue>{to}</styles.FieldValue>
						</styles.FieldWrapper>
						<SharedStyles.Divider />
						<styles.RowFlex>
							<styles.FieldRowName>Fee</styles.FieldRowName>
							<styles.FieldRowValue>
								{valueToEth(gas).toFixed(6)} ETH
								{/* ($ {gasValue?.toFixed(6)} USD) */}
							</styles.FieldRowValue>
						</styles.RowFlex>
						<SharedStyles.Divider />
						<styles.RowFlex>
							<styles.FieldRowName>Max Total</styles.FieldRowName>
							<styles.FieldRowValue>
								{(valueToEth(value) + valueToEth(gas)).toFixed(6)} ETH
								{/* ({txnValue && gasValue
									? (txnValue + gasValue).toFixed(6)
									: 'NaN'}{' '}
								USD) */}
							</styles.FieldRowValue>
						</styles.RowFlex>
					</styles.TextWrap>
					<styles.ButtonWrap>
						<SharedStyles.Divider />
						<styles.ButtonDivWrap>
							<styles.ButtonDiv>
								<SharedStyles.PrimaryButton onClick={() => signTransaction()}>
									Confirm
								</SharedStyles.PrimaryButton>
							</styles.ButtonDiv>
							<styles.ButtonDiv>
								<SharedStyles.SecondaryButton onClick={() => closeWindow()}>
									Cancel
								</SharedStyles.SecondaryButton>
							</styles.ButtonDiv>
						</styles.ButtonDivWrap>
					</styles.ButtonWrap>
				</styles.ContentWrap>
			</styles.Wrapper>
		</styles.ParentWrapper>
	);
};

export default TxnConfirmation;
