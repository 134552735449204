/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getCookie, setCookie } from './cookie';
import { getMetaDataForUsername } from '../services/controller';
import { IWalletMetaData } from '../types/controllerTypes';
import { checkAuth } from '../wallet/checkWallet';
import { generateRandomString } from './randomMessageGenerator';
import { ethers } from 'ethers';

export const getDeviceEncodedId = async (
	walletName: string
): Promise<Partial<IWalletMetaData>> => {
	const deviceEncodedId: any = getCookie(`deviceKeyId${walletName}`);
	const deviceQ0Value: any = getCookie(`deviceQ0Value${walletName}`);
	const deviceQ1Value: any = getCookie(`deviceQ1Value${walletName}`);

	if (deviceEncodedId) {
		return {
			encodedId: deviceEncodedId,
			q0: deviceQ0Value,
			q1: deviceQ1Value,
		};
	}

	const walletMeta: Partial<IWalletMetaData> = await getMetaDataForUsername(
		walletName
	);

	if (walletMeta.keyIds === '') {
		// return walletMeta.encodedId || '';
		return {
			encodedId: walletMeta.encodedId,
			q0: walletMeta.q0,
			q1: walletMeta.q1,
		};
	}

	const keyIds = JSON.parse(walletMeta.keyIds || '');
	const qValuesX = JSON.parse(walletMeta.qValueX || '');
	const qValuesY = JSON.parse(walletMeta.qValueY || '');

	let validKeyId = '',
		validQ0Value = '',
		validQ1Value = '';

	let currentPublicKeyIndex = 0;
	for (const keyId of keyIds) {
		const qValueX: string = qValuesX[currentPublicKeyIndex];
		const qValueY: string = qValuesY[currentPublicKeyIndex];

		const randomMessgae = generateRandomString(50);
		const messageHash = ethers.utils.keccak256(
			ethers.utils.solidityPack(['string'], [randomMessgae])
		);
		let isKeyIdValid = false;
		try {
			isKeyIdValid = await checkAuth(
				randomMessgae,
				messageHash,
				keyId as string,
				[qValueX, qValueY]
			);
		} catch (err) {
			console.log(err);
		}

		if (isKeyIdValid) {
			setCookie(`deviceKeyId${walletName}`, JSON.stringify(keyId as string));
			setCookie(`deviceQ0Value${walletName}`, JSON.stringify(qValueX));
			setCookie(`deviceQ1Value${walletName}`, JSON.stringify(qValueY));

			validKeyId = keyId;
			validQ0Value = qValueX;
			validQ1Value = qValueY;
		}

		currentPublicKeyIndex += 1;
	}

	return {
		encodedId: validKeyId,
		q0: validQ0Value,
		q1: validQ1Value,
	};
};
