/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-useless-catch */
import { IWalletPublicValues } from './createWallet';
import { ethers } from 'ethers';
import { BananaAccount__factory } from '../typechain/factories/BananaAccount__factory';
import { BANANA_SINGLETON_ADDRESS } from '../constants/transactions';
import { Wallet, BananaSigner } from '@rize-labs/banana-wallet-web-sdk';
import { IWalletMetaData } from '../types/controllerTypes';
import {
	getMetaDataForUsername,
	setWalletMetaData,
} from '../services/controller';

//! for now adding new device is enabled for polygon mumbai
export const AddNewDeviceTransaction = async (
	publicKey: IWalletPublicValues,
	provider: ethers.providers.JsonRpcProvider,
	wallet: Wallet,
	walletName: string
) => {
	const BananaAccount = BananaAccount__factory.connect(
		BANANA_SINGLETON_ADDRESS,
		provider
	);

	const { q0, q1, encodedId } = publicKey;
	const encodedIdBytes = ethers.utils.toUtf8Bytes(encodedId);
	const encodedIdHash = ethers.utils.keccak256(encodedIdBytes);
	const addNewDeviceTxnData =
		await BananaAccount.populateTransaction.addNewDevice(
			[q0, q1],
			encodedIdHash
		);
	const walletAddress = await wallet.getAddress();

	const newDeviceTxnObject = {
		to: walletAddress,
		data: addNewDeviceTxnData.data,
		value: 0,
		gasLimit: 3000000,
	};

	const signer: BananaSigner = wallet.getSigner();

	try {
		try {
			const txnReceipt = await signer.sendTransaction(newDeviceTxnObject);
		} catch (err) {
			console.log(err);
		}

		// await txnReceipt.wait();

		//! update database with the object
		const walletMeta: IWalletMetaData = await getMetaDataForUsername(
			walletName
		);

		// eslint-disable-next-line no-prototype-builtins
		if (walletMeta.hasOwnProperty('keyIds')) {
			if (walletMeta.keyIds) {
				const keyIds: string[] = JSON.parse(walletMeta.keyIds);
				keyIds.push(publicKey.encodedId);
				walletMeta.keyIds = JSON.stringify(keyIds);
			} else {
				walletMeta.keyIds = JSON.stringify([publicKey.encodedId]);
			}
		} else {
			walletMeta.keyIds = JSON.stringify([publicKey.encodedId]);
		}

		// eslint-disable-next-line no-prototype-builtins
		if (walletMeta.hasOwnProperty('qValueX')) {
			if (walletMeta.qValueX) {
				const qValueX: string[] = JSON.parse(walletMeta.qValueX);
				qValueX.push(publicKey.q0);
				walletMeta.qValueX = JSON.stringify(qValueX);
			} else {
				walletMeta.qValueX = JSON.stringify([publicKey.q0]);
			}
		} else {
			walletMeta.qValueX = JSON.stringify([publicKey.q0]);
		}

		// eslint-disable-next-line no-prototype-builtins
		if (walletMeta.hasOwnProperty('qValueY')) {
			if (walletMeta.qValueY) {
				const qValueY: string[] = JSON.parse(walletMeta.qValueY);
				qValueY.push(publicKey.q1);
				walletMeta.qValueY = JSON.stringify(qValueY);
			} else {
				walletMeta.qValueY = JSON.stringify([publicKey.q1]);
			}
		} else {
			walletMeta.qValueY = JSON.stringify([publicKey.q1]);
		}

		await setWalletMetaData(walletName, walletMeta);
	} catch (err) {
		throw err;
	}
};
