/* eslint-disable */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useRef, useEffect } from 'react';
import { Modal } from 'antd';
import { QrReader } from 'react-qr-reader';
// import QrScanner from "qr-scanner";

export type ModalRef = {
	showModal: () => void;
};

interface IScannerModalProps {
	setQRContent: (qrContent: string) => void;
	showModal: boolean;
}

const ScannerModal = ({ setQRContent, showModal }: IScannerModalProps) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const videoRef = useRef<HTMLVideoElement>(null);

	useEffect(() => {
		if (!isModalOpen) {
			// qrScanner?.start();
			setIsModalOpen(true);
		}
	}, [showModal]);

	useEffect(() => {
		console.log(videoRef.current);
	}, [videoRef]);

	return (
		<div>
			<Modal
				title="Scan QR"
				open={isModalOpen}
				onCancel={() => setIsModalOpen(false)}
				footer={null}
			>
				<QrReader
					//@ts-ignore
					style={{ width: '100%' }}
					constraints={{
						facingMode: 'user',
					}}
					onResult={(result, error) => {
						if (!!result) {
							//@ts-ignore
							setQRContent(result?.text);
						}
					}}
				/>
			</Modal>
		</div>
	);
};

export default ScannerModal;
