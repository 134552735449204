import React from 'react';
import './UseCases.scss';

const UseCases = () => {
	const useCases = [
		{
			title: 'Your users don’t have a wallet',
			desc: "We enable users to create a wallet on your app using their biometrics; users don't have to download a wallet, remember passphrase and worry about private key security.",
		},
		{
			title: "Your users don't want to connect their wallet to your app",
			desc: "We enable users to create a wallet specifically for your app. Hence users don't have to worry about security vulnerabilities.",
		},
		{
			title: 'Your users want a safer account to use on your app',
			desc: 'We provide authentication using touch-id and face-id and store the keys on the users device, making it as safe as a hardware wallet',
		},
	];

	const renderUseCases = () => {
		return useCases.map((useCase, index) => {
			return (
				<div className="lp-uc-card" key={index}>
					<img
						src={`/assets/images/LandingPage/use-case-${index + 1}.png`}
						alt={`use-case-${index + 1}`}
						className="lp-uc-card-img"
					/>
					<h3 className="lp-uc-card-header">{useCase.title}</h3>
					<p className="lp-uc-card-desc">{useCase.desc}</p>
				</div>
			);
		});
	};

	return (
		<div className="lp-uc-comp">
			<svg
				className="lp-uc-svg"
				viewBox="0 0 1440 321"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M1440 0V321H0C0 -29.7626 1440 350.763 1440 0Z"
					fill="#F5C14B"
				/>
			</svg>
			<div className="lp-uc-div">
				<h1 className="lp-uc-header">Use Cases</h1>
				<p className="lp-uc-sub">
					DeFi, NFT, and FinTech applications can leverage our wallet SDKs and
					provide and In-app
					<br /> wallet to their users.
				</p>
				<div className="lp-uc-cards-div">{renderUseCases()}</div>
			</div>
		</div>
	);
};

export default UseCases;
