/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import Axios from 'axios';

export const ethToUsd = async (ethAmount: number): Promise<number> => {
	const ethPriceResp = await Axios.get(
		'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd'
	);
	//@ts-ignore
	const ethPrice = ethPriceResp.data.ethereum.usd;
	return ethAmount * ethPrice;
};
