/* eslint-disable import/no-extraneous-dependencies */
import { styled } from 'goober';
import { FiDownload } from 'react-icons/fi';

export const styles = {
	Wrapper: styled('div')`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
	`,
	ContainerTxt: styled('p')`
		font-family: 'Urbanist-Medium';
		font-size: 0.875rem;
		color: #bfbfbf;
		text-align: left;
		line-height: 1.2rem;
		margin: 0;
	`,
	BoldTxt: styled('p')`
		font-family: 'Urbanist-Medium';
		font-size: 0.875rem;
		color: #ffffff;
		text-align: left;
		line-height: 1.2rem;
		margin: 0;
	`,
	Icon: styled('img')`
		width: 1.2rem;
		margin-right: 1rem;
	`,
	QrImg: styled('img')`
		width: 15.688rem;
	`,
	ShareIcon: styled(FiDownload)`
		font-size: 1.25rem;
		margin-right: 1vw;
	`,
	BtnWrapper: styled('div')`
		display: flex;
		justify-content: center;
		align-items: center;
	`,
	BtnTxt: styled('p')`
		margin: 0;
		font-family: 'Urbanist-Medium';
		font-size: 1rem;
	`,
};
