/* eslint-disable */
export const setCookie = (name: string, value: string) => {
	try {
		// Check if the cookie already exists
		const existingCookie = getCookie(name);
		if (existingCookie !== null && JSON.stringify(existingCookie) === value) {
			console.log('Cookie already exists with the same value.');
			return;
		} else {
			const date = new Date();
			// setting cookie for 20 years
			date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000 * 20);
			document.cookie = name + '=' + value + '; expires=' + date.toUTCString();
			console.log('Cookie created successfully');
		}
	} catch (error) {
		console.log('An error occurred while setting the cookie: ' + error);
	}
};

// Function to get a cookie
export const getCookie = (name: string) => {
	const nameEQ = name + '=';
	const ca = document.cookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) === ' ') c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) === 0)
			return JSON.parse(c.substring(nameEQ.length, c.length));
	}
	return null;
};

// delete cookie from it's key name
export const deleteCookie = (name: string) => {
	document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};
