/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState } from 'react';
import { styles } from './styles';
import Loader from '../DeviceAdditionOldDeviceFlow/Loader/Loader';
import { BsCircleFill } from 'react-icons/bs';
import GenerateKeys from './GenerateKeys/GenerateKeys';
import ShareQR from './ShareQR/ShareQR';
import { IWalletPublicValues } from '../../../wallet/createWallet';
import { registerAndCreateWallet } from '../../../wallet/registerWallet';
import Qrcode from 'qrcode';
import Success from '../../shared/Success/Success';
import { useParams } from 'react-router-dom';

const DeviceAddNew = () => {
	const [currentStep, setCurrentStep] = useState(0);
	const [qrImageDataUrl, setQrImageDataUrl] = useState<string>('');
	const { additionId } = useParams();

	const onNext = async () => {
		setCurrentStep((prevStep) => prevStep + 1);
		await generateQr();
		setCurrentStep((prevStep) => prevStep + 1);
	};

	const generateQr = async () => {
		const walletPublicValues: IWalletPublicValues =
			await registerAndCreateWallet();
		const stringifiedIWalletPublicValues = JSON.stringify(walletPublicValues);
		const qrDataUrl = await Qrcode.toDataURL(stringifiedIWalletPublicValues);
		setQrImageDataUrl(qrDataUrl);
	};

	return (
		<styles.Container>
			{/* <styles.Nav>
				<styles.NavRow1>
					<styles.Logo src="/assets/images/Logos/banana-logo.png" />
					<styles.NavTitle>Banana</styles.NavTitle>
				</styles.NavRow1>
				<styles.NavSubTitle>Work without limits</styles.NavSubTitle>
			</styles.Nav> */}
			{window.innerWidth < 1024 && (
				<styles.Steps
					current={Math.floor(currentStep)}
					responsive={false}
					labelPlacement="vertical"
					items={[
						{
							title: 'Generate Keys',
							icon: <BsCircleFill className="rs-icon" />,
						},
						{
							title: 'Authenticate',
							icon: <BsCircleFill className="rs-icon" />,
						},
						{
							title: 'Share QR',
							icon: <BsCircleFill className="rs-icon" />,
						},
					]}
				/>
			)}
			<styles.Content>
				{window.innerWidth >= 1024 && (
					<styles.Steps
						current={Math.floor(currentStep)}
						responsive={false}
						labelPlacement="vertical"
						items={[
							{
								title: 'Generate Keys',
								icon: <BsCircleFill className="rs-icon" />,
							},
							{
								title: 'Share QR',
								icon: <BsCircleFill className="rs-icon" />,
							},
							{
								title: 'Authenticate',
								icon: <BsCircleFill className="rs-icon" />,
							},
						]}
					/>
				)}
				{currentStep === 0 && <GenerateKeys onNext={onNext} />}
				{currentStep === 1 && (
					<Loader text="Generating QR for the new device" />
				)}
				{currentStep === 2 && (
					<ShareQR qrImg={qrImageDataUrl} onShare={() => {}} />
				)}
				{currentStep === 3 && (
					<Success title="This device successfully linked to your wallet" />
				)}
			</styles.Content>
		</styles.Container>
	);
};

export default DeviceAddNew;
