/* eslint-disable import/no-extraneous-dependencies */
import { styled } from 'goober';

export const styles = {
	ParentWrapper: styled('div')`
		background-color: #111111;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100vh;
	`,
	Wrapper: styled('div')`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		width: 25%;
		padding: 3.125rem 1rem;
		background-color: #1b1b1b;
		color: #ffffff;
		@media (max-width: 1024px) {
			width: 50%;
		}
		@media (max-width: 768px) {
			width: 90%;
		}
	`,

	TextWrap: styled('div')`
		text-align: left;
		overflow-wrap: break-word;
		word-wrap: break-word;
	`,

	Subtitle: styled('p')`
		font-size: 1.25rem;
		font-family: 'Urbanist-SemiBold';
		color: #ffffff;
		margin-top: 2.5rem;
	`,

    Desc : styled('p')`
        font-size: 0.875rem;
        font-family: 'Urbanist-Regular';
        color: #C0C0C0;
        line-height: 1.313rem;
        width: 100%;
        overflow-wrap: break-word;
        word-wrap: break-word;
    `,
    ButtonWrap : styled('div')`
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        height: 25vh;
    `,
    ContentWrap : styled('div')`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 70vh;
        min-width: 100%;
        width: 100%;
    `,
}
