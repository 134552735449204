/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
	checkWalletExist,
	getMetaDataForUsername,
	setWalletMetaData,
	transportUsername,
} from '../services/controller';
import { registerAndCreateWallet } from './registerWallet';
import { generateRandomString } from '../utils/randomMessageGenerator';
import { checkAuth } from './checkWallet';
import { IWalletMetaData } from '../types/controllerTypes';
import { setCookie, getCookie } from '../utils/cookie';
import { getKeccakHash } from '../utils/getKeccakHash';

export type IWalletPublicValues = {
	q0: string;
	q1: string;
	encodedId: string;
};

export type IWalletCreatrOrConnectResp = {
	isWalletConnectedOrCreated: boolean;
	isError: boolean;
	isConnect: boolean;
	isCreate: boolean;
	walletAddress?: string;
};

export const createWallet = async (
	walletUniqueIdentifier: string,
	sessionId: string,
	isMobile: boolean,
	deepLink?: string
): Promise<IWalletCreatrOrConnectResp> => {
	//! check if wallet with provided name exist
	const isWalletExist = await checkWalletExist(walletUniqueIdentifier);
	let IWalletPublicValues: IWalletPublicValues;
	let WalletMetaData: IWalletMetaData;

	//! We have handled this case during the invocation itself so we don't need to handle it here
	if (isWalletExist) {
		// Now check for does user posses that wallet
		WalletMetaData = await getMetaDataForUsername(walletUniqueIdentifier);
		const randomMessage = generateRandomString(30);
		const messageHash = getKeccakHash(randomMessage);
		const isUserAuthorized = await checkAuth(
			randomMessage,
			messageHash,
			WalletMetaData.encodedId,
			[WalletMetaData.q0, WalletMetaData.q1]
		);
		if (isUserAuthorized) {
			// save wallet metadata to cookie
			let walletIdentifier = getCookie('bananaUser');
			walletIdentifier = JSON.parse(walletIdentifier);
			setCookie(
				'bananaUser',
				JSON.stringify([...walletIdentifier, walletUniqueIdentifier])
			);
			setCookie(walletUniqueIdentifier, JSON.stringify(WalletMetaData));
			const isTransported = await transportUsername({
				sessionId,
				walletUniqueIdentifier,
				isMobile,
			});
			return {
				isWalletConnectedOrCreated: true,
				isError: isTransported,
				isConnect: true,
				isCreate: false,
			};
		}
	} else {
		try {
			IWalletPublicValues = await registerAndCreateWallet();
			const isIWalletMetaDataSaved = await setWalletMetaData(
				walletUniqueIdentifier,
				IWalletPublicValues
			);

			if (deepLink) {
				const deepLinkUrl = `${deepLink}&q0=${IWalletPublicValues.q0}&q1=${IWalletPublicValues.q1}&encodedId=${IWalletPublicValues.encodedId}&username=${walletUniqueIdentifier}`;
				window.open(deepLinkUrl);
			}

			const walletIdentifier = getCookie('bananaUser');
			setCookie(
				`deviceKeyId${walletUniqueIdentifier}`,
				JSON.stringify(IWalletPublicValues.encodedId)
			);
			setCookie(
				`deviceQ0Value${walletUniqueIdentifier}`,
				JSON.stringify(IWalletPublicValues.q0)
			);
			setCookie(
				`deviceQ1Value${walletUniqueIdentifier}`,
				JSON.stringify(IWalletPublicValues.q1)
			);
			setCookie(
				'bananaUser',
				JSON.stringify([...(walletIdentifier || []), walletUniqueIdentifier])
			);
			setCookie(walletUniqueIdentifier, JSON.stringify(IWalletPublicValues));

			const isTransported = await transportUsername({
				sessionId,
				walletUniqueIdentifier,
			});
			//! ig i need to save the metadata to the cookie as well here
			return {
				isWalletConnectedOrCreated: isIWalletMetaDataSaved.success === true,
				isError: false,
				isConnect: false,
				isCreate: true,
			};
		} catch (err) {
			console.log('error caught ', err);
			return {
				isWalletConnectedOrCreated: false,
				isError: true,
				isConnect: false,
				isCreate: false,
			};
		}
	}

	return {
		isWalletConnectedOrCreated: false,
		isError: false,
		isConnect: false,
		isCreate: false,
	};
};
