import React from 'react';
import { styles } from './styles';
import { SharedStyles } from '../../../shared/SharedStyle';

type GenerateKeysProps = {
	onNext: () => void;
};

const GenerateKeys = ({ onNext }: GenerateKeysProps) => {
	return (
		<styles.Wrapper>
			<styles.TextWrapper>
				<styles.BiLink />
				<styles.Text>Link this Device by generating keys.</styles.Text>
			</styles.TextWrapper>
			<SharedStyles.VerticalSpacing height="5vh" />
			<SharedStyles.PrimaryButton onClick={() => onNext()}>
				Next
			</SharedStyles.PrimaryButton>
		</styles.Wrapper>
	);
};

export default GenerateKeys;
