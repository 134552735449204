/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as base64url from '../utils/base64url-arraybuffer';
import { ethers } from 'ethers';
import Axios from 'axios';
import { Banana, Chains } from '@rize-labs/banana-wallet-web-sdk';
import { getKeccakHash } from '../utils/getKeccakHash';

export function buf2hex(buffer: any) {
	// buffer is an ArrayBuffer //@ts-ignore
	return [...new Uint8Array(buffer)]
		.map((x) => x.toString(16).padStart(2, '0'))
		.join('');
}

export const getSignature = async (message: string, encodedId: string) => {
	console.log('this is encoded if ', encodedId);
	try {
		const decodedId = base64url.decode(encodedId);
		const credential = await navigator.credentials.get({
			publicKey: {
				// Set the WebAuthn credential to use for the assertion
				allowCredentials: [
					{
						id: decodedId,
						type: 'public-key',
					},
				],
				challenge: Uint8Array.from(message, (c) => c.charCodeAt(0)).buffer,
				// Set the required authentication factors
				userVerification: 'required',
			},
		});
		if (credential === null) {
			// alert('Failed to get credential')
			return await Promise.reject(new Error('Failed to get credential'));
		}
		//@ts-ignore
		const response = credential.response;

		const clientDataJSON = Buffer.from(response.clientDataJSON);

		const signature = await Axios({
			url: process.env.REACT_APP_LAMDA_VERIFICATION_URL,
			method: 'post',
			params: {
				authDataRaw: JSON.stringify(
					Array.from(new Uint8Array(response.authenticatorData))
				),
				cData: JSON.stringify(
					Array.from(new Uint8Array(response.clientDataJSON))
				),
				signature: JSON.stringify(
					Array.from(new Uint8Array(response.signature))
				),
			},
		});

		const value = clientDataJSON.toString('hex').slice(72, 248);
		const clientDataJsonRequestId = ethers.utils.keccak256('0x' + value);
		const encodedIdHash = ethers.utils.keccak256(
			ethers.utils.toUtf8Bytes(encodedId)
		);
		const combinedSignature =
			signature.data.message.finalSignature +
			clientDataJsonRequestId.slice(2) +
			encodedIdHash.slice(2);

		let signedMessage = '';
		let finalSignature = '';
		let messageSigned = '';
		let finalExtractedSignature = '';
		if (signature.data.message.processStatus === 'success') {
			const abiEncoder = ethers.utils.defaultAbiCoder;
			const decoded = abiEncoder.decode(
				['uint256', 'uint256', 'uint256'],
				combinedSignature
			);
			signedMessage = decoded[2];
			const rHex = decoded[0].toHexString();
			const sHex = decoded[1].toHexString();
			finalSignature = rHex + sHex.slice(2);
			//@ts-ignore
			messageSigned = signedMessage.toHexString();
			finalExtractedSignature = finalSignature;
		}
		const stringBeforeOriginInClientData = '","';
		const stringAfterChallengeInClientData = 'challenge":"';

		const ClientDataJsonStr = new TextDecoder().decode(response.clientDataJSON);
		const clientDataJsonPostStartIndex =
			ClientDataJsonStr.indexOf('origin') -
			stringBeforeOriginInClientData.length;
		const clientDataJsonPreEndIndex =
			ClientDataJsonStr.indexOf('challenge') +
			stringAfterChallengeInClientData.length;
		const sig2 = ethers.utils.defaultAbiCoder.encode(
			['uint', 'uint', 'bytes', 'string', 'string', 'bytes32'],
			[
				ethers.BigNumber.from(
					signature.data.message.finalSignature.slice(0, 66)
				),
				ethers.BigNumber.from(
					'0x' + signature.data.message.finalSignature.slice(66, 130)
				),
				'0x' + buf2hex(response.authenticatorData),
				ClientDataJsonStr.slice(0, clientDataJsonPreEndIndex),
				ClientDataJsonStr.slice(clientDataJsonPostStartIndex),
				getKeccakHash(encodedId),
			]
		);
		/**
		 * Note:
		 * the `message` is signed using secp256r1 instead of secp256k1, hence to verify
		 * signedMessage we cannot use ecrecover!
		 */

		//! Avoiding chain check are that would be too rare case of wallet collision

		return {
			signature: finalExtractedSignature,
			messageSigned,
			combinedSignature,
			isKeyHexSymm: signature.data.message.processStatus === 'success',
			newSignature: sig2,
		};
	} catch (err) {
		console.log('Error caught ', err);
		throw err;
	}
};

const initBananaInstanceAndCheck = async (
	messageSigned: string,
	signature: string,
	eoaAddress: [string, string]
): Promise<boolean> => {
	const bananaInstane = new Banana(Chains.mumbai);
	let resp = false;
	try {
		resp = await bananaInstane.verifySignature(
			signature,
			messageSigned,
			eoaAddress
		);
		console.log('this is resp', resp);
	} catch (err) {
		console.log(err);
	}

	return resp;
};

export const checkAuth = async (
	actualMessage: string,
	messageHash: string,
	encodedId: string,
	eoaAddress: [string, string]
): Promise<boolean> => {
	let isSignatureValid = false;
	let validSignature = '';

	while (!isSignatureValid) {
		const { signature, newSignature } = await getSignature(
			messageHash,
			encodedId
		);
		if (signature.length % 2 === 0) {
			isSignatureValid = true;
			validSignature = newSignature;
		}
	}

	const resp = await initBananaInstanceAndCheck(
		actualMessage,
		validSignature,
		eoaAddress
	);

	return resp;
};
