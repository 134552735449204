/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
const HEX_STRING_REGEX = /[0-9A-Fa-f]{6}/g;

export const isPublicKeyValid = (qrData: string): boolean => {
	try {
		const parsedQrData = JSON.parse(qrData);
		const hasProperty =
			parsedQrData.hasOwnProperty('q0') &&
			parsedQrData.hasOwnProperty('q1') &&
			parsedQrData.hasOwnProperty('encodedId');
		const propertiesAreNonEmpty =
			parsedQrData.q0 !== '' &&
			parsedQrData.q1 !== '' &&
			parsedQrData.encodedId !== '';

		if (hasProperty && propertiesAreNonEmpty) {
			const q0Value = parsedQrData.q0;
			const q1Value = parsedQrData.q1;
			if (
				q0Value.length === q1Value.length &&
				q0Value.length === 66 &&
				q0Value.match(HEX_STRING_REGEX) &&
				q1Value.match(HEX_STRING_REGEX)
			) {
				return true;
			}
		}
	} catch (err) {
		return false;
	}
	return false;
};
