/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import CustomButton from '../../shared/CustomButton/CustomButton';
import './LpIntro.scss';

type lpIntroPropsType = {
	getEarlyAccess: () => void;
};

const LpIntro = (props: lpIntroPropsType) => {
	return (
		<div className="lp-intro-div">
			<div className="lp-intro-txt-div">
				<h1 className="lp-intro-header">Secure. Seamless.</h1>
				<p className="lp-intro-sub">
					Banana is a wallet infrastructure that enables applications to provide
					in-app wallet to their users.
				</p>
				<CustomButton
					text="Get Early Access"
					size="middle"
					onClick={props.getEarlyAccess}
				/>
			</div>
			<div className="lp-intro-img-div">
				<img
					src="/assets/images/LandingPage/intro-div-img.png"
					alt="intro-div-img"
					className="lp-intro-img"
				/>
			</div>
		</div>
	);
};

export default LpIntro;
