/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useEffect, useContext } from 'react';
import { styles } from './styles';
import ModalNav from '../shared/ModalNav/ModalNav';
import { SharedStyles } from '../shared/SharedStyle';
import { useParams } from 'react-router-dom';
import { transportIUserMessageConfigmResponse } from '../../services/controller';
import { getSignature } from '../../wallet/checkWallet';
import { IWalletMetaData } from '../../types/controllerTypes';
import { getCookie } from '../../utils/cookie';
import { toast, Toaster } from 'react-hot-toast';
import { WalletContext } from '../../context/walletContextProvider';
import { WalletContextType } from '../../types/walletContext';
import { getDeviceEncodedId } from '../../utils/getDeviceEncodedId';

const MessageSign = () => {
	const { signatureId } = useParams();
	const [sessionId, setSessionId] = useState<string>();
	//   const [message, setMessage] = useState<string>();
	const queryParameters = new URLSearchParams(window.location.search);
	const messageParam = queryParameters.get('message');
	const walletName = queryParameters.get('walletname');
	const isMobile = queryParameters.get('isMobile');

	// expecting deeplink in the url
	const deepLink = queryParameters.get('deepLink');
	const { walletPlatformMeta } = useContext(WalletContext) as WalletContextType;

	useEffect(() => {
		setSessionId(signatureId);
	}, [signatureId]);

	const signMessage = async () => {
		const walletMeta = await getDeviceEncodedId(walletName || '');

		let isKeySymmetrical = false;
		let combinedSignature = '';

		while (!isKeySymmetrical) {
			const { isKeyHexSymm, newSignature } = await getSignature(
				messageParam || '',
				walletMeta.encodedId || ''
			);
			isKeySymmetrical = isKeyHexSymm;
			combinedSignature = newSignature;
		}

		const signatureTransportationResp =
			await transportIUserMessageConfigmResponse({
				//@ts-ignore
				sessionId,
				signature: combinedSignature,
				isMobile: isMobile === 'true',
			});

		// transporting signature via deeplink
		if (deepLink) {
			const deepLinkSignatureTransportUrl = `${deepLink}&signature=${combinedSignature}}`;
			window.open(deepLinkSignatureTransportUrl);
		}

		if (signatureTransportationResp) {
			toast.success('Signed message redirecting!');
		} else {
			toast.error('unable to sign message');
		}
		setTimeout(() => {
			window.close();
		}, 3000);
	};

	const cancellSignMessage = async () => {
		const signatureTransportationResp =
			await transportIUserMessageConfigmResponse({
				//@ts-ignore
				sessionId,
				signature: 'cancell',
				isMobile: isMobile === 'true',
			});

		if (signatureTransportationResp) {
			toast.error('Signing Cancelled ');
		} else {
			toast.error('Some error occured!! ');
		}

		setTimeout(() => {
			window.close();
		}, 3000);
	};

	return (
		<styles.ParentWrapper>
			<styles.Wrapper>
				<Toaster />
				<ModalNav title="Signature Request" showIcon={true} />
				<styles.ContentWrap>
					<styles.TextWrap>
						<styles.Subtitle>Message:</styles.Subtitle>
						<styles.Desc>{messageParam}</styles.Desc>
					</styles.TextWrap>
					<styles.ButtonWrap>
						<SharedStyles.Divider />
						<SharedStyles.PrimaryButton onClick={() => signMessage()}>
							Sign
						</SharedStyles.PrimaryButton>
						<SharedStyles.SecondaryButton onClick={() => cancellSignMessage()}>
							Cancel
						</SharedStyles.SecondaryButton>
					</styles.ButtonWrap>
				</styles.ContentWrap>
			</styles.Wrapper>
		</styles.ParentWrapper>
	);
};

export default MessageSign;
