/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { Button } from 'antd';
import './SecondaryButton.scss';

type secondaryButtonPropTypes = {
	text: string;
	size: 'large' | 'middle' | 'small';
	onClick: () => void;
	htmlType?: 'submit' | 'button';
	icon?: JSX.Element;
	className?: 'string';
};

const SecondaryButton = (props: secondaryButtonPropTypes) => {
	return (
		<Button
			size={props.size}
			type="default"
			className={`secondary-btn-${props.size}`}
			onClick={props.onClick}
			htmlType={props.htmlType}
		>
			<div className="secondary-btn-content-div">
				{props.icon}
				{props.text}
			</div>
		</Button>
	);
};

export default SecondaryButton;
