/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { ChangeEventHandler } from 'react';
import { Input } from 'antd';
import { BiUser } from 'react-icons/bi';
import { styles } from './styles';
import { handleKeyPress } from '../../../utils/handleKeyPress';

type CustomWalletInputProps = {
	label: string;
	onChange: ChangeEventHandler;
	placeholder: string;
	icon?: JSX.Element;
	className?: string;
	buttonId: string;
};

const CustomWalletInput = (props: CustomWalletInputProps) => {
	return (
		<styles.Wrapper className="cwi">
			<styles.Label className="cwi-label">
				<styles.IconWrapper>{props.icon}</styles.IconWrapper>
				<styles.LabelText>{props.label}</styles.LabelText>
			</styles.Label>
			<styles.Input
				className="cwi-input"
				placeholder={props.placeholder}
				onChange={(e: any) => props.onChange(e)}
				onKeyDown={(e: any) => handleKeyPress(e, props.buttonId)}
			/>
		</styles.Wrapper>
	);
};

export default CustomWalletInput;
