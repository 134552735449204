/* eslint-disable import/no-extraneous-dependencies */
import { styled } from 'goober';

export const styles = {
	Wrapper: styled('div')`
		width: 95%;
		display: flex;
		flex-direction: column;
	`,
	Label: styled('label')`
		margin: 0.7rem auto 0.7rem 0;
		font-size: 22px;
		text-align: left;
		display: flex;
		align-items: center;
		width: 100%;
	`,
	IconWrapper: styled('div')`
		margin-bottom: 0;
		font-size: 24px;
	`,
	LabelText: styled('span')`
		margin-left: 0.5vw;
	`,
	Input: styled('input')`
		box-sizing: border-box;
		padding: 13px 11px;
		color: rgba(255, 255, 255, 0.85);
		font-size: 14px;
		line-height: 1.5714285714285714;
		list-style: none;
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
			'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
			'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
		position: relative;
		display: inline-block;
		width: 100%;
		min-width: 0;
		background-color: #141414;
		background-image: none;
		border-width: 1px;
		border-style: solid;
		border-color: #424242;
		border-radius: 6px;
		transition: all 0.2s;
		margin: auto;
		margin-bottom: 1.3rem;
		&:hover {
			border-color: #ffd42b;
		}
		&:focus {
			border-color: #ffd42b;
			outline: 0;
			box-shadow: 0 0 0 2px rgba(255, 212, 43, 0.2);
		}
	`,
};
