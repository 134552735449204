/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import './OldConnect.scss';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CustomButton from '../shared/CustomButton/CustomButton';
import Axios from 'axios';
import Loader from '../Loader/Loader';
import toast, { Toaster } from 'react-hot-toast';

const OldConnect = () => {
	const { connectId } = useParams();
	const [sessionId, setSessionId] = useState('');
	const [username, setUserName] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const SERVER_URL = process.env.REACT_APP_SERVER_URL;

	useEffect(() => {
		//@ts-ignore
		setSessionId(connectId);
	}, [connectId]);

	const getMetaDataForUsername = async () => {
		const walletMetaDataResp = await Axios({
			url: SERVER_URL + '/get-user-credentials',
			method: 'get',
			params: {
				uniqueIdentifier: username,
			},
		});

		const walletPublicData = JSON.parse(walletMetaDataResp.data.data);

		return walletPublicData;
	};

	const sendUserName = async () => {
		if (!username) {
			toast.error("Username can't be empty");
			return;
		}

		if (!/^[a-zA-Z0-9]+$/.test(username)) {
			toast.error('Only letters and numbers are allowed');
			return;
		}

		setIsLoading(true);

		// check if the walletname is unique
		const isUniqueResp = await Axios({
			url: SERVER_URL + '/check-walletname-exists',
			method: 'post',
			data: {
				walletName: username,
			},
		});

		// let isUserAuthorized = true;
		if (!isUniqueResp.data.isUnique) {
			toast.success('Connecting to your wallet');
		}

		const inputRes = await Axios({
			url: SERVER_URL + '/connect',
			method: 'post',
			data: {
				sessionId,
				username,
			},
		});

		if (inputRes.data.success) {
			toast.success('Successfully! taken username');
		} else {
			toast.error('Some error occured');
		}
		setIsLoading(false);
		window.close();
	};

	const handleKeyPress = async (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			await sendUserName();
		}
	};

	return (
		<div className="connect-body">
			<Toaster />
			<Loader isLoading={isLoading}>
				<div className="connect-logo">
					<img src="/assets/images/Logos/banana-logo.png" alt="Logo" /> <br />
				</div>
				<h3 className="connect-logo-heading"> Banana Wallet</h3>

				<div className="connect-parent-container">
					<div className="connect-container">
						<label className="connect-label">
							Give username to your wallet:
						</label>
						<input
							type="text"
							onChange={(e) => setUserName(e.target.value)}
							name="username"
							className="connect-input"
							placeholder="Username"
							onKeyDown={(e) => handleKeyPress(e)}
						/>
						<CustomButton
							size="middle"
							//@ts-ignore
							className="connect-btn"
							text="Connect"
							onClick={sendUserName}
						/>
					</div>
				</div>
			</Loader>
		</div>
	);
};

export default OldConnect;
