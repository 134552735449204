/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useEffect } from 'react';
import { styles } from './styles';
import { FaRegCopy } from 'react-icons/fa';
import { Button, message } from 'antd'; //! Need to elimiate this antd usage
import { WalletContext } from '../../context/walletContextProvider';
import { WalletContextType } from '../../types/walletContext';
import toast, { Toaster } from 'react-hot-toast';

type walletSuccessProps = {
	title?: string;
	txt?: string;
	imgUrl?: string;
	walletaddress?: string;
};

const WalletSuccess = (props: walletSuccessProps) => {
	const { walletStatus } = useContext(WalletContext) as WalletContextType;

	const copyToCliboard = () => {
		navigator.clipboard.writeText(
			walletStatus?.walletAddress ? walletStatus.walletAddress : ''
		);
		message.success('Wallet Address copied to clipboard!');
	};

	useEffect(() => {
		setTimeout(() => {
			window.close();
		}, 3000);
	});

	return (
		<styles.ParentWrapper>
			<Toaster />
			<styles.Container>
				<styles.Img
					src={
						props.imgUrl
							? props.imgUrl
							: '/assets/images/Recovery/yellow-check.png'
					}
					alt="done"
				/>
				{props.title && <styles.Title>{props.title}</styles.Title>}
				{/* {props.txt && <styles.Txt>{props.txt}</styles.Txt>} */}
				{/* {walletStatus?.walletCreationStatus && (
          <styles.WADiv>
            <FaRegCopy />
            <styles.WalletAddress>{walletStatus.walletAddress}</styles.WalletAddress>
            <styles.CopyBtn onClick={copyToCliboard}>Copy</styles.CopyBtn>
          </styles.WADiv>
        )} */}
			</styles.Container>
		</styles.ParentWrapper>
	);
};

export default WalletSuccess;
