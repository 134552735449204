/* eslint-disable import/no-extraneous-dependencies */
import { styled } from 'goober';

export const Wrapper = styled('div')`
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;
export const Icon = styled('img')`
	width: 1.8rem;
`;
export const IconWrapper = styled('div')`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 0.75rem;
	font-size: 1.2rem;
	cursor: pointer;
`;
export const Text = styled('div')`
	font-family: 'Urbanist-SemiBold';
	font-size: 1.5rem;
	color: #ffffff;
`;
