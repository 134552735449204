import React, { useContext, useEffect } from 'react';
import { FaRegCopy } from 'react-icons/fa';
import { styles } from './styles';
import { Button, message } from 'antd'; //! Need to elimiate this antd usage
import toast, { Toaster } from 'react-hot-toast';

type SuccessProps = {
	title?: string;
};

const Success = (props: SuccessProps) => {
	return (
		// <styles.ParentWrapper>
		<styles.Container>
			<Toaster />
			<styles.Img src={'/assets/images/Recovery/yellow-check.png'} alt="done" />
			{props.title && <styles.Title>{props.title}</styles.Title>}
		</styles.Container>
		// </styles.ParentWrapper>
	);
};

export default Success;
