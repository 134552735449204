/* eslint-disable import/no-extraneous-dependencies */
import { styled } from 'goober';

export const styles = {
	ParentWrapper: styled('div')`
		background-color: #111111;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100vh;
	`,
	Wrapper: styled('div')`
		background-color: #111111;
		color: #ffffff;
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 100vh;
		width: 100%;
	`,
	Sub: styled('p')`
		font-family: 'Urbanist-SemiBold';
		font-size: 1.5rem;
	`,
};
