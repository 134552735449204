/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable import/default */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-undef */
/* eslint-disable import/no-extraneous-dependencies */
import firebase from 'firebase/app';
import '@firebase/storage';
import '@firebase/database';
import 'firebase/firestore';

if (!firebase.apps.length) {
	firebase.initializeApp({
		apiKey: process.env.REACT_APP_API_KEY,
		authDomain: process.env.REACT_APP_AUTH_DOMAIN,
		projectId: process.env.REACT_APP_PROJECT_ID,
		storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
		messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
		appId: process.env.REACT_APP_APP_ID,
	});
}

const db = firebase.firestore();

export const addLead = async (info) => {
	try {
		let resp = await db.collection('leads').add({
			name: info.name,
			company: info.company,
			email: info.email,
			social: info.social,
		});
		return 'Thanks for your interest !!';
	} catch (error) {
		console.log(error.message);
		return 'Some error occured';
	}
};
